"use strict";

var _interopRequireWildcard = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("/root/workspace/front-end_micro-page_oo0i/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

require("/root/workspace/front-end_micro-page_oo0i/node_modules/core-js/modules/es6.array.iterator.js");

require("/root/workspace/front-end_micro-page_oo0i/node_modules/core-js/modules/es6.promise.js");

require("/root/workspace/front-end_micro-page_oo0i/node_modules/core-js/modules/es6.object.assign.js");

require("/root/workspace/front-end_micro-page_oo0i/node_modules/core-js/modules/es7.promise.finally.js");

var _vue = _interopRequireDefault(require("vue"));

var _jsCookie = _interopRequireDefault(require("js-cookie"));

require("normalize.css/normalize.css");

var _elementUi = _interopRequireDefault(require("element-ui"));

require("./styles/element-variables.scss");

require("./styles/index.scss");

var _vueClipboard = _interopRequireDefault(require("vue-clipboard2"));

var _ckeditor5Vue = _interopRequireDefault(require("@ckeditor/ckeditor5-vue"));

var _axios = _interopRequireDefault(require("axios"));

var _main = require("./organizationLibs/vueSsoSdk/main");

var _App = _interopRequireDefault(require("./App"));

var _store = _interopRequireDefault(require("./store"));

var _router = _interopRequireDefault(require("./router"));

var filters = _interopRequireWildcard(require("./filters"));

var _lang = _interopRequireDefault(require("./lang"));

require("./icons");

require("./permission");

var _Blob = _interopRequireDefault(require("./excel/Blob"));

require("@ckeditor/ckeditor5-build-classic/build/translations/zh-cn");

// A modern alternative to CSS resets
// global css
// eslint-disable-next-line import/no-unresolved
// global filters
// Internationalization
// icon
// permission control

/**
 * If you don't want to use mock-server
 * you want to use mockjs for request interception
 * you can execute:
 *
 // * import { mockXHR } from '../mock'
 * mockXHR()
 */
_vue.default.prototype.$axios = _axios.default;
_axios.default.defaults.baseURL = '/api';
_vue.default.config.productionTip = false;
_vue.default.prototype.$vm = new _vue.default();

_vue.default.use(_elementUi.default, {
  size: _jsCookie.default.get('size') || 'medium',
  // set element-ui default size
  i18n: function i18n(key, value) {
    return _lang.default.t(key, value);
  }
});

_vue.default.use(_ckeditor5Vue.default);

_vue.default.use(_vueClipboard.default);

_vue.default.use(_Blob.default);

Object.keys(filters).forEach(function (key) {
  _vue.default.filter(key, filters[key]);
});
_vue.default.config.productionTip = false; // 设置 sso sdk

(0, _main.updateConfig)({
  accessTokenKey: process.env.VUE_APP_COOKIE_ACCESS_TOKEN_KEY,
  refreshTokenKey: process.env.VUE_APP_COOKIE_REFRESH_TOKEN_KEY,
  apiUrl: process.env.VUE_APP_LOGIN_API,
  ssoUrl: _store.default.state.microPage.isZqPartner ? process.env.VUE_APP_ZQ_SSO_URL : process.env.VUE_APP_SSO_URL,
  registerFrom: process.env.VUE_APP_SYSTEM_OPEN_ID,
  routerRef: _router.default,
  devAccessToken: process.env.VUE_APP_AUTH_DEV_ACCESS_TOKEN,
  devRefreshToken: process.env.VUE_APP_AUTH_DEV_REFRESH_TOKEN
}); // eslint-disable-next-line no-new

new _vue.default({
  el: '#app',
  router: _router.default,
  store: _store.default,
  i18n: _lang.default,
  render: function render(h) {
    return h(_App.default);
  }
});