var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "select-dialogue" },
    [
      _c(
        "el-dialog",
        {
          staticClass: "dialogue",
          attrs: {
            title: _vm.title,
            visible: _vm.visible,
            width: "1050px",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "before-close": _vm.cancelDialogue
          },
          on: {
            "update:visible": function($event) {
              _vm.visible = $event
            }
          }
        },
        [
          _vm.type === "book_shelf"
            ? _c(
                "div",
                {
                  staticClass: "flex-row align-center",
                  attrs: { slot: "title" },
                  slot: "title"
                },
                [
                  _c("div", { staticClass: "book-dialog-title" }, [
                    _vm._v("选择图书")
                  ]),
                  _vm._v(" "),
                  !_vm.isZq
                    ? _c("search-user", {
                        attrs: { "partner-open-id": _vm.partnerOpenId },
                        on: { receivepartnerId: _vm.receivePartnerId }
                      })
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex-row flex-space-between align-center" },
            [
              _c(
                "div",
                { staticClass: "content-and-search" },
                [
                  _vm.type === "book_shelf"
                    ? _c("book-content", {
                        ref: "bookContent",
                        attrs: {
                          "partner-open-id": _vm.partnerOpenId,
                          "model-key": _vm.modelKey,
                          "result-list": _vm.tableCheckList
                        },
                        on: {
                          handleChecked: _vm.handleChecked,
                          handlePartner: _vm.handlePartner
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.type === "coupon"
                    ? _c("coupon-content", {
                        ref: "coupon",
                        attrs: {
                          "result-list": _vm.tableCheckList,
                          "model-key": _vm.modelKey
                        },
                        on: { handleChecked: _vm.handleChecked }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.type === "famous_teachers"
                    ? _c("famous-teacher", {
                        attrs: { "result-list": _vm.tableCheckList },
                        on: { handleChecked: _vm.handleChecked }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.type === "live"
                    ? _c("live-content", {
                        attrs: { "result-list": _vm.tableCheckList },
                        on: { handleChecked: _vm.handleChecked }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.type === "test_paper"
                    ? _c("test-paper", {
                        attrs: {
                          "model-key": _vm.modelKey,
                          "result-list": _vm.tableCheckList
                        },
                        on: { handleChecked: _vm.handleChecked }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.type === "test_questions"
                    ? _c("test-questions", {
                        attrs: {
                          "model-key": _vm.modelKey,
                          "result-list": _vm.tableCheckList
                        },
                        on: { handleChecked: _vm.handleChecked }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.type === "cloud_retail_goods"
                    ? _c("product-content", {
                        attrs: {
                          "model-key": _vm.modelKey,
                          "result-list": _vm.tableCheckList
                        },
                        on: { handleChecked: _vm.handleChecked }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c("div", [_c("i", { staticClass: "el-icon-d-arrow-right" })]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "select-result" },
                [
                  _c("select-result", {
                    attrs: { type: _vm.type, "result-list": _vm.resultList },
                    on: {
                      handleResult: _vm.handleResult,
                      handleDeleteResult: _vm.handleDeleteResult
                    }
                  })
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c("el-button", { on: { click: _vm.cancelDialogue } }, [
                _vm._v("取消")
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.loading, type: "primary" },
                  on: { click: _vm.submitDialogue }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }