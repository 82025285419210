"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

var _common = require("../../utils/common");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'PictureNavigate',
  props: {
    value: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    options: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    size: {
      type: Number,
      default: undefined
    },
    isCloudRetailPartner: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    pictureStyle: function pictureStyle() {
      if (this.size === 2) {
        return 'two-picture';
      }

      if (this.size === 4) {
        return 'four-picture';
      }

      if (this.size === 5) {
        return 'five-picture';
      }

      return 'three-picture';
    },
    textWidth: function textWidth() {
      if (this.size === 2) {
        return '155px';
      }

      if (this.size === 4) {
        return '75px';
      }

      if (this.size === 5) {
        return '60px';
      }

      return '100px';
    },
    isNoTaoZhi: function isNoTaoZhi() {
      return this.isCloudRetailPartner || this.$store.state.microPage.isZqPartner;
    }
  },
  methods: {
    // 处理图片
    getImgUrl: function getImgUrl(cover) {
      var style = this.size === 4 ? 'style/width-limit-128' : 'style/width-limit-256';

      if (cover) {
        if (cover.substring(0, 4) === 'http') {
          return cover;
        }
      }

      return (0, _common.imageUrlFun)(cover, style);
    }
  }
};
exports.default = _default2;