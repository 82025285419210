var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "widget-config-case" },
    [
      _vm.show
        ? _c(
            "div",
            [
              _c("div", { staticClass: "components-title" }, [
                _c("span", [_vm._v(_vm._s(_vm.data.title))])
              ]),
              _vm._v(" "),
              _c(
                "el-menu",
                {
                  staticClass: "widget-menu",
                  attrs: {
                    "default-active": "1",
                    "default-openeds": _vm.openedList
                  }
                },
                [
                  _c(
                    "el-submenu",
                    { attrs: { index: "1" } },
                    [
                      _c("template", { slot: "title" }, [_vm._v("基础设置")]),
                      _vm._v(" "),
                      _c(
                        "el-menu-item-group",
                        {
                          staticClass: "flex-column",
                          staticStyle: { padding: "0 20px" }
                        },
                        [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.data.options.isAppearance,
                                  expression: "data.options.isAppearance"
                                }
                              ],
                              staticClass:
                                "book-title flex-column bottom-border margin-bottom-fourteen"
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "margin-bottom-fourteen" },
                                [_vm._v("组件外观")]
                              ),
                              _vm._v(" "),
                              _vm.data.type === "input"
                                ? _c(
                                    "div",
                                    [
                                      _c("div", { staticClass: "flex-row" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "flex-column appearance-border align-center flex-justify-center",
                                            class:
                                              _vm.data.options.style !==
                                              "cloudRetail"
                                                ? "appearance-border-active"
                                                : "",
                                            on: {
                                              click: function($event) {
                                                _vm.data.options.style =
                                                  "default"
                                              }
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "el-icon-c-scale-to-original"
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "margin-top-ten" },
                                              [_vm._v("样式1")]
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "flex-column appearance-border align-center flex-justify-center",
                                            class:
                                              _vm.data.options.style ===
                                              "cloudRetail"
                                                ? "appearance-border-active"
                                                : "",
                                            on: {
                                              click: function($event) {
                                                _vm.data.options.style =
                                                  "cloudRetail"
                                              }
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "el-icon-bank-card"
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "margin-top-ten" },
                                              [_vm._v("样式2")]
                                            )
                                          ]
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "padding-fourteen" },
                                        [_vm._v("背景样式")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-radio-group",
                                        {
                                          staticClass:
                                            "margin-bottom-fourteen ",
                                          attrs: { size: "mini" },
                                          on: {
                                            change: _vm.changeComponentRadio
                                          },
                                          model: {
                                            value: _vm.data.options.appearance,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.data.options,
                                                "appearance",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "data.options.appearance"
                                          }
                                        },
                                        [
                                          _c("el-radio-button", {
                                            attrs: { label: "白色" }
                                          }),
                                          _vm._v(" "),
                                          _c("el-radio-button", {
                                            attrs: { label: "主题色" }
                                          }),
                                          _vm._v(" "),
                                          _c("el-radio-button", {
                                            attrs: { label: "自定义" }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex-row flex-warp margin-bottom-fourteen"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex-column appearance-border\n                 align-center flex-justify-center ",
                                          class:
                                            _vm.data.options.appearance ===
                                            "open"
                                              ? "appearance-border-active"
                                              : "",
                                          on: {
                                            click: function($event) {
                                              _vm.data.options.appearance =
                                                "open"
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "el-icon-c-scale-to-original"
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticClass: "margin-top-ten" },
                                            [_vm._v("展开")]
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex-column appearance-border\n                 align-center flex-justify-center",
                                          class:
                                            _vm.data.options.appearance ===
                                            "card"
                                              ? "appearance-border-active"
                                              : "",
                                          on: {
                                            click: function($event) {
                                              _vm.data.options.appearance =
                                                "card"
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-bank-card"
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticClass: "margin-top-ten" },
                                            [_vm._v("卡片")]
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                              _vm._v(" "),
                              _vm.data.options.appearance === "自定义"
                                ? _c("div", [
                                    _vm.data.options.style !== "cloudRetail"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "margin-bottom-fourteen"
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "common-color-title"
                                              },
                                              [_vm._v("背景颜色")]
                                            ),
                                            _vm._v(" "),
                                            _c("color-picker", {
                                              attrs: {
                                                "default-color": "#ffffff"
                                              },
                                              model: {
                                                value:
                                                  _vm.data.options.color
                                                    .background,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.data.options.color,
                                                    "background",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "data.options.color.background"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "margin-fourteen" },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "common-color-title" },
                                          [_vm._v("搜索背景颜色")]
                                        ),
                                        _vm._v(" "),
                                        _c("color-picker", {
                                          attrs: { "default-color": "#efefef" },
                                          model: {
                                            value:
                                              _vm.data.options.color
                                                .searchBackground,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.data.options.color,
                                                "searchBackground",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "data.options.color.searchBackground"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "margin-bottom-fourteen" },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "common-color-title" },
                                          [_vm._v("字体颜色")]
                                        ),
                                        _vm._v(" "),
                                        _c("color-picker", {
                                          attrs: { "default-color": "#ffffff" },
                                          model: {
                                            value: _vm.data.options.color.font,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.data.options.color,
                                                "font",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "data.options.color.font"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "margin-bottom-fourteen" },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "common-color-title" },
                                          [_vm._v("图标颜色")]
                                        ),
                                        _vm._v(" "),
                                        _c("color-picker", {
                                          attrs: { "default-color": "#ffffff" },
                                          model: {
                                            value: _vm.data.options.color.icon,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.data.options.color,
                                                "icon",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "data.options.color.icon"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ])
                                : _vm._e()
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.data.options.isStyle &&
                                    (_vm.data.type !== "micro_video" ||
                                      (_vm.data.type === "micro_video" &&
                                        !_vm.isCloudRetailPartner)),
                                  expression:
                                    "data.options.isStyle &&(data.type !=='micro_video' || ((data.type ==='micro_video' && !isCloudRetailPartner)))"
                                }
                              ],
                              staticClass:
                                "book-title flex-column bottom-border margin-bottom-fourteen"
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "margin-bottom-fourteen" },
                                [_vm._v("组件风格")]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "flex-row flex-warp margin-bottom-fourteen"
                                },
                                [
                                  _vm.data.type === "carousel" ||
                                  _vm.data.type === "app_carousel"
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex-column appearance-border align-center flex-justify-center",
                                          class:
                                            _vm.data.options.style ===
                                            "carousel"
                                              ? "appearance-border-active"
                                              : "",
                                          on: {
                                            click: function($event) {
                                              _vm.data.options.style =
                                                "carousel"
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-bank-card"
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticClass: "margin-top-ten" },
                                            [_vm._v("轮播")]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.data.type === "carousel" ||
                                  _vm.data.type === "app_carousel"
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex-column appearance-border align-center flex-justify-center",
                                          class:
                                            _vm.data.options.style === "slide"
                                              ? "appearance-border-active"
                                              : "",
                                          on: {
                                            click: function($event) {
                                              _vm.data.options.style = "slide"
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-bank-card"
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticClass: "margin-top-ten" },
                                            [_vm._v("左右滑动")]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.data.type === "book_shelf" ||
                                  _vm.data.type === "app_audio_book"
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex-column appearance-border align-center flex-justify-center",
                                          class:
                                            _vm.data.options.style === "picture"
                                              ? "appearance-border-active"
                                              : "",
                                          on: {
                                            click: function($event) {
                                              _vm.data.options.style = "picture"
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-notebook-1"
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticClass: "margin-top-ten" },
                                            [_vm._v("书籍展示1")]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.data.type === "live"
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex-column appearance-border align-center flex-justify-center",
                                          class:
                                            _vm.data.options.style === "picture"
                                              ? "appearance-border-active"
                                              : "",
                                          on: {
                                            click: function($event) {
                                              _vm.data.options.style = "picture"
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-full-screen"
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticClass: "margin-top-ten" },
                                            [_vm._v("大图显示")]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.data.type === "live" ||
                                  _vm.data.type === "book_shelf" ||
                                  _vm.data.type === "app_audio_book" ||
                                  _vm.data.type === "knowledge_goods" ||
                                  _vm.data.type === "ranking_list" ||
                                  _vm.data.type === "test_questions" ||
                                  _vm.data.type === "micro_audio" ||
                                  _vm.data.type === "micro_video" ||
                                  _vm.data.type === "cloud_retail_goods"
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex-column appearance-border align-center flex-justify-center",
                                          class:
                                            _vm.data.options.style === "line"
                                              ? "appearance-border-active"
                                              : "",
                                          on: {
                                            click: function($event) {
                                              return _vm.handleVideo("line")
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-tickets"
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticClass: "margin-top-ten" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.lineName) + "列表"
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.data.type === "coupon"
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex-column appearance-border align-center flex-justify-center",
                                          class:
                                            _vm.data.options.style === "line"
                                              ? "appearance-border-active"
                                              : "",
                                          on: {
                                            click: function($event) {
                                              _vm.data.options.style = "line"
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-tickets"
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticClass: "margin-top-ten" },
                                            [_vm._v("列表展示")]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.data.type === "knowledge_goods" ||
                                  _vm.data.type === "ranking_list" ||
                                  _vm.data.type === "test_questions" ||
                                  _vm.data.type === "cloud_retail_goods"
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex-column appearance-border align-center flex-justify-center",
                                          class:
                                            _vm.data.options.style ===
                                            "smallPicture"
                                              ? "appearance-border-active"
                                              : "",
                                          on: {
                                            click: function($event) {
                                              _vm.data.options.style =
                                                "smallPicture"
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-tickets"
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticClass: "margin-top-ten" },
                                            [_vm._v("小图列表")]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.data.type === "cloud_retail_goods"
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex-column appearance-border align-center flex-justify-center",
                                          class:
                                            _vm.data.options.style === "card"
                                              ? "appearance-border-active"
                                              : "",
                                          on: {
                                            click: function($event) {
                                              _vm.data.options.style = "card"
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-tickets"
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticClass: "margin-top-ten" },
                                            [_vm._v("卡片列表")]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.data.type === "cloud_retail_goods"
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex-column appearance-border align-center flex-justify-center",
                                          class:
                                            _vm.data.options.style === "three"
                                              ? "appearance-border-active"
                                              : "",
                                          on: {
                                            click: function($event) {
                                              _vm.data.options.style = "three"
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-tickets"
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticClass: "margin-top-ten" },
                                            [_vm._v("一列三行")]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.data.type === "knowledge_goods" ||
                                  _vm.data.type === "ranking_list" ||
                                  _vm.data.type === "coupon" ||
                                  _vm.data.type === "test_questions" ||
                                  _vm.data.type === "app_audio_book" ||
                                  _vm.data.type === "book_shelf" ||
                                  _vm.data.type === "cloud_retail_goods"
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex-column appearance-border align-center flex-justify-center",
                                          class:
                                            _vm.data.options.style === "slide"
                                              ? "appearance-border-active"
                                              : "",
                                          on: {
                                            click: function($event) {
                                              _vm.data.options.style = "slide"
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-tickets"
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticClass: "margin-top-ten" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  [
                                                    "app_audio_book",
                                                    "book_shelf"
                                                  ].includes(_vm.data.type)
                                                    ? "横向"
                                                    : "左右"
                                                ) + "滚动"
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.data.type === "cloud_retail_goods"
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex-column appearance-border align-center flex-justify-center",
                                          class:
                                            _vm.data.options.style ===
                                            "waterfall"
                                              ? "appearance-border-active"
                                              : "",
                                          on: {
                                            click: function($event) {
                                              _vm.data.options.style =
                                                "waterfall"
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-tickets"
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticClass: "margin-top-ten" },
                                            [_vm._v("瀑布流")]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.data.type === "official_account" ||
                                  _vm.data.type === "partner_info"
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex-column appearance-border\n                    align-center flex-justify-center",
                                          class:
                                            _vm.data.options.style === "default"
                                              ? "appearance-border-active"
                                              : "",
                                          on: {
                                            click: function($event) {
                                              _vm.data.options.style = "default"
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-tickets"
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticClass: "margin-top-ten" },
                                            [_vm._v("默认样式")]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.data.type === "partner_info"
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex-column appearance-border\n                    align-center flex-justify-center",
                                          class:
                                            _vm.data.options.style === "center"
                                              ? "appearance-border-active"
                                              : "",
                                          on: {
                                            click: function($event) {
                                              _vm.data.options.style = "center"
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-tickets"
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticClass: "margin-top-ten" },
                                            [_vm._v("居中样式")]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.data.type === "partner_info"
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex-column appearance-border\n                    align-center flex-justify-center",
                                          class:
                                            _vm.data.options.style === "tile"
                                              ? "appearance-border-active"
                                              : "",
                                          on: {
                                            click: function($event) {
                                              _vm.data.options.style = "tile"
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-tickets"
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticClass: "margin-top-ten" },
                                            [_vm._v("平铺样式")]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.data.type === "micro_audio" ||
                                  _vm.data.type === "micro_video"
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex-column appearance-border\n                    align-center flex-justify-center",
                                          class:
                                            _vm.data.options.style === "column"
                                              ? "appearance-border-active"
                                              : "",
                                          on: {
                                            click: function($event) {
                                              return _vm.handleVideo("column")
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "iconfont icon-shuxiangpailie"
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticClass: "margin-top-ten" },
                                            [_vm._v("纵向目录")]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.data.type === "micro_video"
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex-column appearance-border\n                    align-center flex-justify-center",
                                          class:
                                            _vm.data.options.style === "row"
                                              ? "appearance-border-active"
                                              : "",
                                          on: {
                                            click: function($event) {
                                              return _vm.handleVideo("row")
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "iconfont icon-hengxiangpailie"
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticClass: "margin-top-ten" },
                                            [_vm._v("横向目录")]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.data.type === "micro_word"
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex-column appearance-border align-center flex-justify-center",
                                          class:
                                            _vm.data.options.style === "read"
                                              ? "appearance-border-active"
                                              : "",
                                          on: {
                                            click: function($event) {
                                              _vm.data.options.style = "read"
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "iconfont icon-huirenzi"
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticClass: "margin-top-ten" },
                                            [_vm._v("会认字")]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.data.type === "micro_word"
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex-column appearance-border align-center flex-justify-center",
                                          class:
                                            _vm.data.options.style === "write"
                                              ? "appearance-border-active"
                                              : "",
                                          on: {
                                            click: function($event) {
                                              _vm.data.options.style = "write"
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "iconfont icon-huixiezi"
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticClass: "margin-top-ten" },
                                            [_vm._v("会写字")]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.data.type === "micro_polyphonic"
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex-column appearance-border align-center flex-justify-center",
                                          class:
                                            _vm.data.options.style === "line"
                                              ? "appearance-border-active"
                                              : "",
                                          on: {
                                            click: function($event) {
                                              _vm.data.options.style = "line"
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "iconfont icon-shuxiangpailie"
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticClass: "margin-top-ten" },
                                            [_vm._v("纵向列表")]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.data.type === "micro_polyphonic"
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex-column appearance-border align-center flex-justify-center",
                                          class:
                                            _vm.data.options.style === "nav"
                                              ? "appearance-border-active"
                                              : "",
                                          on: {
                                            click: function($event) {
                                              _vm.data.options.style = "nav"
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "iconfont icon-daohangqiehuan"
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticClass: "margin-top-ten" },
                                            [_vm._v("导航切换")]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.data.type === "countdown"
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex-column appearance-border align-center flex-justify-center",
                                          class:
                                            _vm.data.options.style === "one"
                                              ? "appearance-border-active"
                                              : "",
                                          on: {
                                            click: function($event) {
                                              return _vm.handleOptionsStyle(
                                                "one"
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "el-icon-c-scale-to-original"
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticClass: "margin-top-ten" },
                                            [_vm._v("样式1")]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.data.type === "countdown"
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex-column appearance-border align-center flex-justify-center",
                                          class:
                                            _vm.data.options.style === "two"
                                              ? "appearance-border-active"
                                              : "",
                                          on: {
                                            click: function($event) {
                                              return _vm.handleOptionsStyle(
                                                "two"
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-bank-card"
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticClass: "margin-top-ten" },
                                            [_vm._v("样式2")]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.data.type === "tool"
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex-column appearance-border\n                    align-center flex-justify-center",
                                          class:
                                            _vm.data.options.style === "column"
                                              ? "appearance-border-active"
                                              : "",
                                          on: {
                                            click: function($event) {
                                              _vm.data.options.style = "column"
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "iconfont icon-shuxiangpailie"
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticClass: "margin-top-ten" },
                                            [_vm._v("纵向排列")]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.data.type === "tool"
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex-column appearance-border\n                    align-center flex-justify-center",
                                          class:
                                            _vm.data.options.style === "row"
                                              ? "appearance-border-active"
                                              : "",
                                          on: {
                                            click: function($event) {
                                              return _vm.handleVideo("row")
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "iconfont icon-hengxiangpailie"
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticClass: "margin-top-ten" },
                                            [_vm._v("横向排列")]
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          (_vm.data.type === "book_shelf" ||
                            _vm.data.type === "app_audio_book") &&
                          _vm.data.options.style !== "line" &&
                          _vm.data.options.style !== "slide"
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "book-title flex-column bottom-border margin-bottom-fourteen"
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "margin-bottom-fourteen" },
                                    [_vm._v("名称位置")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex-row flex-warp margin-bottom-fourteen"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex-column appearance-border align-center flex-justify-center",
                                          class:
                                            _vm.data.options.namePosition ===
                                            "0"
                                              ? "appearance-border-active"
                                              : "",
                                          on: {
                                            click: function($event) {
                                              return _vm.handleBookShelf("0")
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-bank-card"
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticClass: "margin-top-ten" },
                                            [_vm._v("图片下方")]
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex-column appearance-border align-center flex-justify-center",
                                          class:
                                            _vm.data.options.namePosition ===
                                            "1"
                                              ? "appearance-border-active"
                                              : "",
                                          on: {
                                            click: function($event) {
                                              return _vm.handleBookShelf("1")
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-bank-card"
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticClass: "margin-top-ten" },
                                            [_vm._v("图片内下方")]
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.data.type === "picture_navigate",
                                  expression: "data.type === 'picture_navigate'"
                                }
                              ],
                              staticClass:
                                "book-title flex-column bottom-border margin-bottom-fourteen"
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "margin-bottom-fourteen" },
                                [_vm._v("导航文字位置")]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "flex-row flex-warp margin-bottom-fourteen"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex-column appearance-border\n                    align-center flex-justify-center",
                                      class:
                                        _vm.data.options.style ===
                                        "outsideBottom"
                                          ? "appearance-border-active"
                                          : "",
                                      on: {
                                        click: function($event) {
                                          return _vm.handlePictureStyle(
                                            "outsideBottom"
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "iconfont icon-tupianxiafang"
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "margin-top-ten" },
                                        [_vm._v("图片下方")]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex-column appearance-border\n                    align-center flex-justify-center",
                                      class:
                                        _vm.data.options.style === "innerBottom"
                                          ? "appearance-border-active"
                                          : "",
                                      on: {
                                        click: function($event) {
                                          return _vm.handlePictureStyle(
                                            "innerBottom"
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "iconfont icon-tupianneixiafang"
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "margin-top-ten" },
                                        [_vm._v("图片内下方")]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex-column appearance-border\n                     align-center flex-justify-center",
                                      class:
                                        _vm.data.options.style === "innerCenter"
                                          ? "appearance-border-active"
                                          : "",
                                      on: {
                                        click: function($event) {
                                          return _vm.handlePictureStyle(
                                            "innerCenter"
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "iconfont icon-tupianneijuzhong1"
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "margin-top-ten" },
                                        [_vm._v("图片内居中")]
                                      )
                                    ]
                                  )
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _vm.data.type === "image_navigate" ||
                          _vm.data.type === "app_image_navigate" ||
                          _vm.data.type === "cloud_retail_partner"
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "book-title flex-column bottom-border margin-bottom-fourteen"
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.data.type === "cloud_retail_partner"
                                          ? "店铺图形状"
                                          : "导航形状"
                                      )
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "el-radio-group",
                                    {
                                      staticClass:
                                        "margin-top-bottom-seventeen",
                                      model: {
                                        value: _vm.data.options.shape,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.data.options,
                                            "shape",
                                            $$v
                                          )
                                        },
                                        expression: "data.options.shape"
                                      }
                                    },
                                    [
                                      _c(
                                        "el-radio-button",
                                        { attrs: { label: "round" } },
                                        [_vm._v("圆形")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-radio-button",
                                        { attrs: { label: "square" } },
                                        [_vm._v("方形")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.data.type === "micro_title"
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "flex-column bottom-border margin-bottom-fourteen"
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "margin-bottom-fourteen" },
                                    [_vm._v("主标题设置")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "common-color-title" },
                                    [_vm._v("字体颜色")]
                                  ),
                                  _vm._v(" "),
                                  _c("color-picker", {
                                    attrs: { "default-color": "#101010" },
                                    model: {
                                      value: _vm.data.options.color.mainFont,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.data.options.color,
                                          "mainFont",
                                          $$v
                                        )
                                      },
                                      expression: "data.options.color.mainFont"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex-row flex-space-between align-center bottom-border padding-fourteen"
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "flex-column" },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "common-color-title"
                                            },
                                            [_vm._v("字号")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-select",
                                            {
                                              attrs: { placeholder: "请选择" },
                                              model: {
                                                value:
                                                  _vm.data.content.mainSize,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.data.content,
                                                    "mainSize",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "data.content.mainSize"
                                              }
                                            },
                                            _vm._l(_vm.sizeOptions, function(
                                              item
                                            ) {
                                              return _c("el-option", {
                                                key: item.value,
                                                attrs: {
                                                  label: item.label,
                                                  value: item.value
                                                }
                                              })
                                            }),
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex-column micro-title-select"
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "common-color-title"
                                            },
                                            [_vm._v("粗细")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-select",
                                            {
                                              attrs: { placeholder: "请选择" },
                                              model: {
                                                value:
                                                  _vm.data.content.mainWeight,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.data.content,
                                                    "mainWeight",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "data.content.mainWeight"
                                              }
                                            },
                                            [
                                              _c("el-option", {
                                                attrs: {
                                                  value: 400,
                                                  label: "标准"
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c("el-option", {
                                                attrs: {
                                                  value: 700,
                                                  label: "加粗"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "margin-fourteen" },
                                    [_vm._v("副标题设置")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "common-color-title" },
                                    [_vm._v("字体颜色")]
                                  ),
                                  _vm._v(" "),
                                  _c("color-picker", {
                                    attrs: { "default-color": "#999999" },
                                    model: {
                                      value: _vm.data.options.color.subFont,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.data.options.color,
                                          "subFont",
                                          $$v
                                        )
                                      },
                                      expression: "data.options.color.subFont"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex-row flex-space-between align-center padding-fourteen"
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "flex-column" },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "common-color-title"
                                            },
                                            [_vm._v("字号")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-select",
                                            {
                                              attrs: { placeholder: "请选择" },
                                              model: {
                                                value: _vm.data.content.subSize,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.data.content,
                                                    "subSize",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "data.content.subSize"
                                              }
                                            },
                                            _vm._l(_vm.sizeOptions, function(
                                              item
                                            ) {
                                              return _c("el-option", {
                                                key: item.value,
                                                attrs: {
                                                  label: item.label,
                                                  value: item.value
                                                }
                                              })
                                            }),
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex-column micro-title-select"
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "common-color-title"
                                            },
                                            [_vm._v("粗细")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-select",
                                            {
                                              attrs: { placeholder: "请选择" },
                                              model: {
                                                value:
                                                  _vm.data.content.subWeight,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.data.content,
                                                    "subWeight",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "data.content.subWeight"
                                              }
                                            },
                                            [
                                              _c("el-option", {
                                                attrs: {
                                                  value: 400,
                                                  label: "标准"
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c("el-option", {
                                                attrs: {
                                                  value: 700,
                                                  label: "加粗"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.data.type === "partner_info"
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "padding-bottom-fourteen bottom-border flex-column"
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "color-title-margin" },
                                    [_vm._v("字体颜色")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "color-title-margin common-color-title"
                                    },
                                    [_vm._v("商户名称字体色")]
                                  ),
                                  _vm._v(" "),
                                  _c("color-picker", {
                                    attrs: { "default-color": "#101010" },
                                    model: {
                                      value: _vm.data.options.color.partnerName,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.data.options.color,
                                          "partnerName",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "data.options.color.partnerName"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "margin-fourteen color-title-margin common-color-title"
                                    },
                                    [_vm._v("统计内容字体色")]
                                  ),
                                  _vm._v(" "),
                                  _c("color-picker", {
                                    attrs: { "default-color": "#101010" },
                                    model: {
                                      value: _vm.data.options.color.statistics,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.data.options.color,
                                          "statistics",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "data.options.color.statistics"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.data.type === "countdown"
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "padding-bottom-fourteen bottom-border flex-column"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "margin-top-fourteen padding-bottom-fourteen"
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "color-title-margin" },
                                        [_vm._v("标题文字颜色")]
                                      ),
                                      _vm._v(" "),
                                      _c("color-picker", {
                                        attrs: { "default-color": "#ffffff" },
                                        model: {
                                          value: _vm.data.options.color.font,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.data.options.color,
                                              "font",
                                              $$v
                                            )
                                          },
                                          expression: "data.options.color.font"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "margin-bottom-fourteen padding-bottom-fourteen bottom-border"
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "color-title-margin" },
                                        [_vm._v("提示语颜色")]
                                      ),
                                      _vm._v(" "),
                                      _c("color-picker", {
                                        attrs: { "default-color": "#7A7C7B" },
                                        model: {
                                          value:
                                            _vm.data.options.color.tipColor,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.data.options.color,
                                              "tipColor",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "data.options.color.tipColor"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "margin-bottom-fourteen" },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "color-title-margin" },
                                        [_vm._v("时间文字颜色")]
                                      ),
                                      _vm._v(" "),
                                      _c("color-picker", {
                                        attrs: { "default-color": "#101010" },
                                        model: {
                                          value:
                                            _vm.data.options.color.timeTxtColor,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.data.options.color,
                                              "timeTxtColor",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "data.options.color.timeTxtColor"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "margin-bottom-fourteen" },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "color-title-margin" },
                                        [_vm._v("时间背景颜色")]
                                      ),
                                      _vm._v(" "),
                                      _c("color-picker", {
                                        attrs: { "default-color": "#101010" },
                                        model: {
                                          value:
                                            _vm.data.options.color.timeBgColor,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.data.options.color,
                                              "timeBgColor",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "data.options.color.timeBgColor"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "margin-bottom-fourteen" },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "color-title-margin" },
                                        [_vm._v("时间符号颜色")]
                                      ),
                                      _vm._v(" "),
                                      _c("color-picker", {
                                        attrs: { "default-color": "#101010" },
                                        model: {
                                          value:
                                            _vm.data.options.color
                                              .timeSymbolColor,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.data.options.color,
                                              "timeSymbolColor",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "data.options.color.timeSymbolColor"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.data.type === "image_navigate" ||
                          _vm.data.type === "app_image_navigate" ||
                          _vm.data.type === "rich_text" ||
                          _vm.data.type === "partner_info" ||
                          _vm.data.type === "micro_title" ||
                          _vm.data.type === "picture_navigate" ||
                          _vm.data.type === "cloud_retail_partner" ||
                          _vm.data.type === "countdown"
                            ? _c(
                                "div",
                                {
                                  class: [
                                    _vm.data.type === "cloud_retail_partner"
                                      ? ""
                                      : "bottom-border"
                                  ]
                                },
                                [
                                  _vm.data.type !== "countdown"
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "margin-top-fourteen padding-bottom-fourteen"
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "color-title-margin"
                                            },
                                            [_vm._v("背景颜色")]
                                          ),
                                          _vm._v(" "),
                                          _c("color-picker", {
                                            attrs: {
                                              "default-color": "#ffffff"
                                            },
                                            model: {
                                              value:
                                                _vm.data.options.color
                                                  .background,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.data.options.color,
                                                  "background",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "data.options.color.background"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.data.type === "image_navigate" ||
                                  _vm.data.type === "app_image_navigate" ||
                                  _vm.data.type === "picture_navigate"
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "margin-bottom-fourteen"
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "color-title-margin"
                                            },
                                            [_vm._v("字体颜色")]
                                          ),
                                          _vm._v(" "),
                                          _c("color-picker", {
                                            attrs: {
                                              "default-color": "#101010"
                                            },
                                            model: {
                                              value:
                                                _vm.data.options.color.font,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.data.options.color,
                                                  "font",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "data.options.color.font"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  (_vm.data.type === "partner_info" &&
                                    _vm.data.options.style !== "tile") ||
                                  _vm.data.type === "micro_title" ||
                                  _vm.data.type === "countdown"
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "margin-top-fourteen padding-bottom-fourteen"
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "color-title-margin"
                                            },
                                            [_vm._v("背景图片")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "flex-row flex-space-between"
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "common-color-title"
                                                },
                                                [_vm._v("是否显示背景图")]
                                              ),
                                              _vm._v(" "),
                                              _c("el-switch", {
                                                attrs: {
                                                  "active-color": "#13ce66"
                                                },
                                                on: { change: _vm.showTitleBg },
                                                model: {
                                                  value:
                                                    _vm.data.options.showBgImg,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.data.options,
                                                      "showBgImg",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "data.options.showBgImg"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _vm.data.type === "countdown"
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "margin-bottom-fourteen"
                                                },
                                                [
                                                  _c(
                                                    "el-radio-group",
                                                    {
                                                      attrs: { size: "mini" },
                                                      model: {
                                                        value:
                                                          _vm.data.options
                                                            .bgMode,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.data.options,
                                                            "bgMode",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "data.options.bgMode"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "el-radio-button",
                                                        {
                                                          attrs: {
                                                            label: "default"
                                                          }
                                                        },
                                                        [_vm._v("模版")]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "el-radio-button",
                                                        {
                                                          attrs: {
                                                            label: "custom"
                                                          }
                                                        },
                                                        [_vm._v("自定义")]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          (_vm.data.type === "countdown" &&
                                            _vm.data.options.bgMode ===
                                              "custom") ||
                                          _vm.data.type !== "countdown"
                                            ? _c(
                                                "div",
                                                {
                                                  style:
                                                    _vm.data.type ===
                                                    "micro_title"
                                                      ? {
                                                          display: "flex",
                                                          "align-items":
                                                            "flex-end"
                                                        }
                                                      : ""
                                                },
                                                [
                                                  (_vm.data.type ===
                                                    "micro_title" &&
                                                    _vm.data.options
                                                      .showBgImg) ||
                                                  _vm.data.type ===
                                                    "partner_info" ||
                                                  _vm.data.type === "countdown"
                                                    ? _c(
                                                        "select-material-btn",
                                                        {
                                                          attrs: {
                                                            "data-item":
                                                              _vm.partnerBackgroundImg,
                                                            type: _vm.data.type,
                                                            "icon-text":
                                                              "添加背景图片",
                                                            path:
                                                              _vm.data.options
                                                                .backgroundImage,
                                                            "img-width-height": {
                                                              width:
                                                                _vm.data
                                                                  .type ===
                                                                "partner_info"
                                                                  ? "190px"
                                                                  : "160px",
                                                              height: "80px"
                                                            }
                                                          },
                                                          on: {
                                                            submitMaterial:
                                                              _vm.submitMaterial
                                                          }
                                                        }
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm.data.type ===
                                                    "micro_title" &&
                                                  _vm.data.options.showBgImg
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "micro_title_bg_text"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "建议图片尺寸750rpx * 144rpx"
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.data.type === "countdown" &&
                                          _vm.data.options.bgMode === "default"
                                            ? _c(
                                                "div",
                                                [
                                                  _c(
                                                    "el-select",
                                                    {
                                                      attrs: {
                                                        placeholder:
                                                          "请选择模版"
                                                      },
                                                      on: {
                                                        change:
                                                          _vm.changeCountdownMode
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.data.options
                                                            .modeType,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.data.options,
                                                            "modeType",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "data.options.modeType"
                                                      }
                                                    },
                                                    _vm._l(
                                                      _vm.countdownMode,
                                                      function(item) {
                                                        return _c("el-option", {
                                                          key: item.value,
                                                          attrs: {
                                                            label: item.label,
                                                            value: item.value
                                                          }
                                                        })
                                                      }
                                                    ),
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.data.type === "cloud_retail_partner"
                            ? _c("div", [
                                _c(
                                  "div",
                                  { staticClass: "margin-bottom-fourteen" },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "color-title-margin" },
                                      [_vm._v("店名文字颜色")]
                                    ),
                                    _vm._v(" "),
                                    _c("color-picker", {
                                      attrs: { "default-color": "#2A536B" },
                                      model: {
                                        value:
                                          _vm.data.options.color.partnerName,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.data.options.color,
                                            "partnerName",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "data.options.color.partnerName"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "margin-bottom-fourteen" },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "color-title-margin" },
                                      [_vm._v("电话文字颜色")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "flex-row align-center flex-space-between"
                                      },
                                      [
                                        _c("color-picker", {
                                          attrs: { "default-color": "#606266" },
                                          model: {
                                            value: _vm.data.options.color.phone,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.data.options.color,
                                                "phone",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "data.options.color.phone"
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("el-checkbox", {
                                          staticStyle: {
                                            "margin-left": "12px"
                                          },
                                          model: {
                                            value:
                                              _vm.data.options.showPhoneTxt,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.data.options,
                                                "showPhoneTxt",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "data.options.showPhoneTxt"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "padding-bottom-fourteen margin-bottom-fourteen bottom-border"
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "color-title-margin" },
                                      [_vm._v("店地址文字颜色")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "flex-row align-center flex-space-between "
                                      },
                                      [
                                        _c("color-picker", {
                                          attrs: { "default-color": "#606266" },
                                          model: {
                                            value:
                                              _vm.data.options.color.address,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.data.options.color,
                                                "address",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "data.options.color.address"
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("el-checkbox", {
                                          staticStyle: {
                                            "margin-left": "12px"
                                          },
                                          model: {
                                            value:
                                              _vm.data.options.showAddressTxt,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.data.options,
                                                "showAddressTxt",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "data.options.showAddressTxt"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.data.type === "partner_info"
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "flex-column bottom-border padding-fourteen"
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "color-title-margin" },
                                    [_vm._v("关注按钮")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-radio-group",
                                    {
                                      attrs: { size: "mini" },
                                      model: {
                                        value: _vm.data.followBtn.style,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.data.followBtn,
                                            "style",
                                            $$v
                                          )
                                        },
                                        expression: "data.followBtn.style"
                                      }
                                    },
                                    [
                                      _c(
                                        "el-radio-button",
                                        { attrs: { label: "default" } },
                                        [_vm._v("默认")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-radio-button",
                                        { attrs: { label: "custom" } },
                                        [_vm._v("自定义")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-radio-button",
                                        { attrs: { label: "none" } },
                                        [_vm._v("无")]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _vm.data.followBtn.style === "custom"
                                    ? _c(
                                        "div",
                                        { staticClass: "margin-top-fourteen" },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "common-color-title"
                                            },
                                            [_vm._v("背景色")]
                                          ),
                                          _vm._v(" "),
                                          _c("color-picker", {
                                            attrs: {
                                              "default-color": "#ffffff"
                                            },
                                            model: {
                                              value:
                                                _vm.data.followBtn.background,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.data.followBtn,
                                                  "background",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "data.followBtn.background"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "margin-top-fourteen common-color-title"
                                            },
                                            [_vm._v("字体色")]
                                          ),
                                          _vm._v(" "),
                                          _c("color-picker", {
                                            attrs: {
                                              "default-color": "#101010"
                                            },
                                            model: {
                                              value: _vm.data.followBtn.font,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.data.followBtn,
                                                  "font",
                                                  $$v
                                                )
                                              },
                                              expression: "data.followBtn.font"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.data.type === "types_navigate"
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "flex-column bottom-border padding-fourteen"
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "color-title-margin" },
                                    [_vm._v("导航设置")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "common-color-title" },
                                    [_vm._v("字体颜色")]
                                  ),
                                  _vm._v(" "),
                                  _c("color-picker", {
                                    attrs: { "default-color": "#409EFF" },
                                    model: {
                                      value:
                                        _vm.data.options.color.activityFont,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.data.options.color,
                                          "activityFont",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "data.options.color.activityFont"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "common-color-title margin-fourteen"
                                    },
                                    [_vm._v("下划线颜色")]
                                  ),
                                  _vm._v(" "),
                                  _c("color-picker", {
                                    attrs: { "default-color": "#409EFF" },
                                    model: {
                                      value: _vm.data.options.color.underline,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.data.options.color,
                                          "underline",
                                          $$v
                                        )
                                      },
                                      expression: "data.options.color.underline"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.data.type === "carousel" ||
                          _vm.data.type === "app_carousel" ||
                          _vm.data.type === "image_advert"
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "book-title flex-column bottom-border margin-bottom-fourteen"
                                },
                                [
                                  _c("span", [_vm._v("高度")]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex-row align-center margin-bottom-fourteen"
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "block" },
                                        [
                                          _c("el-slider", {
                                            staticStyle: { width: "200px" },
                                            attrs: {
                                              max:
                                                _vm.data.type === "app_carousel"
                                                  ? 400
                                                  : 750,
                                              min: 0
                                            },
                                            model: {
                                              value:
                                                _vm.data.options.imageHeight,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.data.options,
                                                  "imageHeight",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "data.options.imageHeight"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("el-input", {
                                        staticStyle: {
                                          width: "56px",
                                          "margin-left": "12px"
                                        },
                                        attrs: {
                                          size: "mini",
                                          oninput:
                                            "value=value.replace(/[^\\d]/g,'')",
                                          maxLength: "3"
                                        },
                                        model: {
                                          value: _vm.data.options.imageHeight,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.data.options,
                                              "imageHeight",
                                              $$v
                                            )
                                          },
                                          expression: "data.options.imageHeight"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: { "margin-left": "12px" }
                                        },
                                        [_vm._v("px")]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.data.type === "micro_audio" ||
                          _vm.data.type === "micro_video"
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "bottom-border padding-bottom-fourteen"
                                },
                                [
                                  _vm.data.type === "micro_video"
                                    ? _c(
                                        "div",
                                        [
                                          _c("span", [_vm._v("视频比例")]),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "padding-fourteen" },
                                            [
                                              _vm._v("\n                  长"),
                                              _c("el-input", {
                                                staticClass: "common-screen",
                                                model: {
                                                  value:
                                                    _vm.data.options
                                                      .screenWidth,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.data.options,
                                                      "screenWidth",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "data.options.screenWidth"
                                                }
                                              }),
                                              _vm._v(" : 宽"),
                                              _c("el-input", {
                                                staticClass: "common-screen",
                                                model: {
                                                  value:
                                                    _vm.data.options
                                                      .screenHeight,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.data.options,
                                                      "screenHeight",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "data.options.screenHeight"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("span", [_vm._v("全屏设置")]),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "flex-row flex-space-between margin-top-fourteen"
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "common-color-title"
                                                },
                                                [_vm._v("允许全屏")]
                                              ),
                                              _c("el-switch", {
                                                attrs: {
                                                  "active-color": "#13ce66"
                                                },
                                                model: {
                                                  value:
                                                    _vm.data.options
                                                      .isScreenFull,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.data.options,
                                                      "isScreenFull",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "data.options.isScreenFull"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _vm.data.options.isScreenFull
                                            ? _c(
                                                "el-radio-group",
                                                {
                                                  staticClass:
                                                    "margin-bottom-fourteen",
                                                  attrs: { size: "medium" },
                                                  model: {
                                                    value:
                                                      _vm.data.options
                                                        .screenFull,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.data.options,
                                                        "screenFull",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "data.options.screenFull"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "el-radio-button",
                                                    {
                                                      attrs: {
                                                        label: undefined,
                                                        value: undefined
                                                      }
                                                    },
                                                    [_vm._v("默认")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "el-radio-button",
                                                    {
                                                      attrs: {
                                                        label: -90,
                                                        value: -90
                                                      }
                                                    },
                                                    [_vm._v("横屏")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "el-radio-button",
                                                    {
                                                      attrs: {
                                                        label: 0,
                                                        value: 0
                                                      }
                                                    },
                                                    [_vm._v("竖屏")]
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  !_vm.isCloudRetailPartner
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex-row flex-space-between"
                                        },
                                        [
                                          _c(
                                            "div",
                                            [
                                              _vm._v(
                                                "\n                  自动播放\n                  "
                                              ),
                                              _c(
                                                "el-tooltip",
                                                {
                                                  staticClass: "item",
                                                  attrs: {
                                                    effect: "dark",
                                                    content:
                                                      "开启后, " +
                                                      (_vm.data.type ===
                                                      "micro_video"
                                                        ? "视频"
                                                        : "音频") +
                                                      "将依次连续往下播放",
                                                    placement: "top"
                                                  }
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass: "el-icon-info",
                                                    staticStyle: {
                                                      "margin-left": "5px",
                                                      color: "#409EFF",
                                                      "font-size": "14px"
                                                    }
                                                  })
                                                ]
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("el-switch", {
                                            attrs: {
                                              "active-color": "#13ce66"
                                            },
                                            model: {
                                              value: _vm.data.options.autoPlay,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.data.options,
                                                  "autoPlay",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "data.options.autoPlay"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.data.type === "micro_audio"
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex-row flex-space-between margin-top-fourteen"
                                        },
                                        [
                                          _vm._v("允许下载 "),
                                          _c("el-switch", {
                                            attrs: {
                                              "active-color": "#13ce66"
                                            },
                                            model: {
                                              value: _vm.data.options.downLoad,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.data.options,
                                                  "downLoad",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "data.options.downLoad"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.data.type === "micro_video" &&
                                  !_vm.isCloudRetailPartner
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex-row flex-space-between margin-top-fourteen"
                                        },
                                        [
                                          _vm._v("显示标题 "),
                                          _c("el-switch", {
                                            attrs: {
                                              "active-color": "#13ce66"
                                            },
                                            model: {
                                              value: _vm.data.options.showTitle,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.data.options,
                                                  "showTitle",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "data.options.showTitle"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.data.type === "image_advert" ||
                          _vm.data.type === "rich_text" ||
                          (_vm.data.type === "micro_video" &&
                            _vm.isCloudRetailPartner)
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "book-title flex-column bottom-border margin-fourteen "
                                },
                                [
                                  _c("span", { staticClass: "book-title" }, [
                                    _vm._v("外观内边距")
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex-row margin-top-bottom-seventeen"
                                    },
                                    [
                                      _c(
                                        "el-radio",
                                        {
                                          attrs: { label: "fill" },
                                          on: {
                                            change: function($event) {
                                              _vm.data.options.innerMargin =
                                                "fill"
                                            }
                                          },
                                          model: {
                                            value: _vm.data.options.innerMargin,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.data.options,
                                                "innerMargin",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "data.options.innerMargin"
                                          }
                                        },
                                        [_vm._v("填充")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-radio",
                                        {
                                          attrs: { label: "margin" },
                                          on: {
                                            change: function($event) {
                                              _vm.data.options.innerMargin =
                                                "margin"
                                            }
                                          },
                                          model: {
                                            value: _vm.data.options.innerMargin,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.data.options,
                                                "innerMargin",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "data.options.innerMargin"
                                          }
                                        },
                                        [_vm._v("留边")]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.data.type === "micro_word" ||
                          _vm.data.type === "micro_polyphonic" ||
                          _vm.data.type === "micro_terms"
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "book-title flex-column margin-top-fourteen"
                                },
                                [
                                  _vm.data.type === "micro_word"
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex-row flex-space-between padding-fourteen"
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "book-title" },
                                            [_vm._v("开启概览")]
                                          ),
                                          _vm._v(" "),
                                          _c("el-switch", {
                                            attrs: {
                                              "active-color": "#13ce66"
                                            },
                                            model: {
                                              value: _vm.data.options.overview,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.data.options,
                                                  "overview",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "data.options.overview"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.data.type !== "input"
                            ? _c(
                                "div",
                                {
                                  class: [
                                    "book-title",
                                    "flex-column",
                                    "margin-top-fourteen",
                                    [
                                      "cloud_retail_partner",
                                      "cloud_retail_goods"
                                    ].includes(_vm.data.type)
                                      ? "bottom-border"
                                      : ""
                                  ]
                                },
                                [
                                  _c("span", { staticClass: "book-title" }, [
                                    _vm._v("辅助间距")
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex-row margin-top-bottom-seventeen"
                                    },
                                    [
                                      _c(
                                        "el-checkbox",
                                        {
                                          model: {
                                            value: _vm.data.options.spacingTop,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.data.options,
                                                "spacingTop",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "data.options.spacingTop"
                                          }
                                        },
                                        [_vm._v("上间距")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-checkbox",
                                        {
                                          model: {
                                            value:
                                              _vm.data.options.spacingBottom,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.data.options,
                                                "spacingBottom",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "data.options.spacingBottom"
                                          }
                                        },
                                        [_vm._v("下间距")]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.data.type === "partner_info"
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "book-title flex-row flex-space-between padding-fourteen"
                                },
                                [
                                  _c("span", { staticClass: "book-title" }, [
                                    _vm._v("沉浸式")
                                  ]),
                                  _vm._v(" "),
                                  _c("el-switch", {
                                    attrs: {
                                      disabled:
                                        _vm.data.options.style === "tile",
                                      "active-color": "#13ce66"
                                    },
                                    on: { change: _vm.changeImmerseSwitch },
                                    model: {
                                      value: _vm.data.options.immerse,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.data.options,
                                          "immerse",
                                          $$v
                                        )
                                      },
                                      expression: "data.options.immerse"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.data.type === "input"
                            ? _c(
                                "div",
                                [
                                  _c("span", { staticClass: "book-title" }, [
                                    _vm._v("搜索提示语")
                                  ]),
                                  _vm._v(" "),
                                  _c("el-input", {
                                    staticClass: "margin-top-bottom-seventeen",
                                    attrs: { placeholder: "请输入内容" },
                                    model: {
                                      value: _vm.data.options.placeholder,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.data.options,
                                          "placeholder",
                                          $$v
                                        )
                                      },
                                      expression: "data.options.placeholder"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.data.type === "cloud_retail_partner"
                            ? _c("div", { staticClass: "padding-fourteen" }, [
                                _c(
                                  "span",
                                  { staticClass: "color-title-margin" },
                                  [_vm._v("按钮")]
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "flex-row" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "all-border radius-four navigate-radio"
                                    },
                                    [
                                      _c("el-checkbox", {
                                        staticClass: "navigate-radio-absolute",
                                        model: {
                                          value:
                                            _vm.data.options.showAddressIcon,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.data.options,
                                              "showAddressIcon",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "data.options.showAddressIcon"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex-column flex-justify-center align-center flex-warp"
                                        },
                                        [
                                          _c("el-image", {
                                            staticClass: "check-img",
                                            attrs: {
                                              src: _vm.getImgUrl(
                                                "assets/taozhi/shopMiniProgram/home/home-address.png",
                                                "style/width-limit-64"
                                              )
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "margin-top": "5px"
                                              }
                                            },
                                            [_vm._v("一键导航")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "all-border radius-four navigate-radio"
                                    },
                                    [
                                      _c("el-checkbox", {
                                        staticClass: "navigate-radio-absolute",
                                        model: {
                                          value: _vm.data.options.showPhoneIcon,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.data.options,
                                              "showPhoneIcon",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "data.options.showPhoneIcon"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex-column flex-justify-center align-center flex-warp"
                                        },
                                        [
                                          _c("el-image", {
                                            staticClass: "check-img",
                                            attrs: {
                                              src: _vm.getImgUrl(
                                                "assets/taozhi/shopMiniProgram/home/home-phone.png",
                                                "style/width-limit-64"
                                              )
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "margin-top": "5px"
                                              }
                                            },
                                            [_vm._v("拨打电话")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ])
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.data.type === "cloud_retail_goods" &&
                          _vm.data.setPrice
                            ? _c(
                                "div",
                                {
                                  staticClass: "flex-column margin-top-fifteen"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex-column bottom-border common-background-input padding-bottom-fourteen"
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "margin-bottom-fourteen"
                                        },
                                        [_vm._v("价格显示")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "flex-row" },
                                        [
                                          _c(
                                            "el-checkbox",
                                            {
                                              model: {
                                                value:
                                                  _vm.data.price.showReality,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.data.price,
                                                    "showReality",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "data.price.showReality"
                                              }
                                            },
                                            [_vm._v("实际价格")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-checkbox",
                                            {
                                              model: {
                                                value:
                                                  _vm.data.price.showOriginal,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.data.price,
                                                    "showOriginal",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "data.price.showOriginal"
                                              }
                                            },
                                            [_vm._v("划线价格")]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.data.type !== "cloud_retail_goods"
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex-row flex-space-between padding-fourteen"
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "margin-bottom": "0"
                                              }
                                            },
                                            [_vm._v("VIP标签显示")]
                                          ),
                                          _vm._v(" "),
                                          _c("el-switch", {
                                            attrs: {
                                              "active-color": "#13ce66"
                                            },
                                            model: {
                                              value: _vm.data.price.showVipIcon,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.data.price,
                                                  "showVipIcon",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "data.price.showVipIcon"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.data.type === "cloud_retail_goods"
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "flex-column common-background-input"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "padding-fourteen flex-row flex-space-between"
                                    },
                                    [
                                      _c("span", [_vm._v("启用商品套餐购买")]),
                                      _vm._v(" "),
                                      _c("el-switch", {
                                        attrs: { "active-color": "#13ce66" },
                                        on: { change: _vm.changeSetPackageBuy },
                                        model: {
                                          value: _vm.data.options.setPackageBuy,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.data.options,
                                              "setPackageBuy",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "data.options.setPackageBuy"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _vm.data.options.setPackageBuy
                                    ? _c(
                                        "span",
                                        { staticClass: "common-color-title" },
                                        [_vm._v("按钮名称")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.data.options.setPackageBuy
                                    ? _c("el-input", {
                                        staticClass: " padding-bottom-fourteen",
                                        attrs: {
                                          clearable: "",
                                          placeholder: "请输入内容"
                                        },
                                        model: {
                                          value: _vm.data.options.packageBuyBtn,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.data.options,
                                              "packageBuyBtn",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "data.options.packageBuyBtn"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      )
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _vm.data.type === "book_shelf" ||
                  _vm.data.type === "app_audio_book"
                    ? _c(
                        "el-submenu",
                        { attrs: { index: "2" } },
                        [
                          _c("template", { slot: "title" }, [
                            _vm._v("外观设置")
                          ]),
                          _vm._v(" "),
                          _vm.data.type === "book_shelf" ||
                          _vm.data.type === "app_audio_book"
                            ? _c(
                                "el-menu-item-group",
                                { staticStyle: { padding: "0 20px" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex-column bottom-border padding-bottom-fourteen"
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "color-title-margin" },
                                        [_vm._v("字体颜色")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "color-title-margin common-color-title"
                                        },
                                        [_vm._v("标题字体色")]
                                      ),
                                      _vm._v(" "),
                                      _c("color-picker", {
                                        attrs: { "default-color": "#101010" },
                                        model: {
                                          value: _vm.data.options.titleColor,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.data.options,
                                              "titleColor",
                                              $$v
                                            )
                                          },
                                          expression: "data.options.titleColor"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "margin-fourteen color-title-margin common-color-title"
                                        },
                                        [_vm._v("名称字体色")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex-row align-center flex-space-between"
                                        },
                                        [
                                          _c("color-picker", {
                                            attrs: {
                                              "default-color": "#999999"
                                            },
                                            model: {
                                              value:
                                                _vm.data.options.resourceColor,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.data.options,
                                                  "resourceColor",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "data.options.resourceColor"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("el-checkbox", {
                                            staticStyle: {
                                              "margin-left": "12px"
                                            },
                                            model: {
                                              value:
                                                _vm.data.options
                                                  .showResourceTitle,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.data.options,
                                                  "showResourceTitle",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "data.options.showResourceTitle"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex-row flex-space-between margin-top-fourteen"
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "common-color-title",
                                              staticStyle: {
                                                "margin-bottom": "0"
                                              }
                                            },
                                            [_vm._v("是否显示全部名称")]
                                          ),
                                          _vm._v(" "),
                                          _c("el-switch", {
                                            attrs: {
                                              "active-color": "#13ce66"
                                            },
                                            model: {
                                              value:
                                                _vm.data.options.showAllTitle,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.data.options,
                                                  "showAllTitle",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "data.options.showAllTitle"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "margin-fourteen color-title-margin common-color-title"
                                        },
                                        [_vm._v("价格字体色")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex-row align-center flex-space-between"
                                        },
                                        [
                                          _c("color-picker", {
                                            attrs: {
                                              "default-color": "#F85862"
                                            },
                                            model: {
                                              value:
                                                _vm.data.options.priceColor,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.data.options,
                                                  "priceColor",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "data.options.priceColor"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "el-tooltip",
                                            {
                                              attrs: {
                                                effect: "dark",
                                                content:
                                                  "默认不勾选，即默认不显示价格",
                                                placement: "top-end"
                                              }
                                            },
                                            [
                                              _c("el-checkbox", {
                                                staticStyle: {
                                                  "margin-left": "12px"
                                                },
                                                model: {
                                                  value:
                                                    _vm.data.options.showPrice,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.data.options,
                                                      "showPrice",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "data.options.showPrice"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "flex-column" },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "margin-fourteen color-title-margin book-title"
                                        },
                                        [_vm._v("背景颜色")]
                                      ),
                                      _vm._v(" "),
                                      _c("color-picker", {
                                        attrs: { "default-color": "#ffffff" },
                                        model: {
                                          value:
                                            _vm.data.options.color.background,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.data.options.color,
                                              "background",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "data.options.color.background"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex-row align-center flex-space-between"
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "margin-fourteen color-title-margin book-title"
                                            },
                                            [_vm._v("背景图片")]
                                          ),
                                          _vm._v(" "),
                                          _c("el-switch", {
                                            attrs: {
                                              "active-color": "#13ce66"
                                            },
                                            model: {
                                              value: _vm.data.options.showBgImg,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.data.options,
                                                  "showBgImg",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "data.options.showBgImg"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.filterNoTitle(_vm.data.type) && _vm.data.setTitle
                    ? _c(
                        "el-submenu",
                        { attrs: { index: "3" } },
                        [
                          _c("template", { slot: "title" }, [
                            _vm._v("标题设置")
                          ]),
                          _vm._v(" "),
                          _vm.data.setTitle
                            ? _c(
                                "el-menu-item-group",
                                {
                                  staticClass: "flex-column",
                                  staticStyle: { padding: "0 20px" }
                                },
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      staticClass: "margin-bottom-fourteen",
                                      attrs: { size: "mini" },
                                      on: { change: _vm.changeTitleRadio },
                                      model: {
                                        value: _vm.data.titleData.style,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.data.titleData,
                                            "style",
                                            $$v
                                          )
                                        },
                                        expression: "data.titleData.style"
                                      }
                                    },
                                    [
                                      _c(
                                        "el-radio-button",
                                        { attrs: { label: "default" } },
                                        [_vm._v("默认")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-radio-button",
                                        { attrs: { label: "custom" } },
                                        [_vm._v("自定义")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-radio-button",
                                        { attrs: { label: "none" } },
                                        [_vm._v("无")]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _vm.data.titleData.style === "custom"
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "common-background-input padding-bottom-fourteen"
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "common-color-title"
                                            },
                                            [_vm._v("标题名称")]
                                          ),
                                          _vm._v(" "),
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "请填写标题名称"
                                            },
                                            model: {
                                              value: _vm.data.titleData.title,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.data.titleData,
                                                  "title",
                                                  $$v
                                                )
                                              },
                                              expression: "data.titleData.title"
                                            }
                                          }),
                                          _vm._v(" "),
                                          ![
                                            "micro_word",
                                            "micro_polyphonic",
                                            "micro_terms",
                                            "app_home_book_shelf",
                                            "tool"
                                          ].includes(_vm.data.type)
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "common-color-title margin-top-fourteen"
                                                },
                                                [_vm._v("查看更多")]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          ![
                                            "micro_word",
                                            "micro_polyphonic",
                                            "micro_terms",
                                            "app_home_book_shelf",
                                            "tool"
                                          ].includes(_vm.data.type)
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "flex-row flex-space-between align-center"
                                                },
                                                [
                                                  _c("el-input", {
                                                    attrs: {
                                                      placeholder:
                                                        "请填写查看更多"
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.data.titleData.more,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.data.titleData,
                                                          "more",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "data.titleData.more"
                                                    }
                                                  }),
                                                  _vm._v(" "),
                                                  _c("el-checkbox", {
                                                    staticStyle: {
                                                      "margin-left": "12px"
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.data.titleData
                                                          .isShowMore,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.data.titleData,
                                                          "isShowMore",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "data.titleData.isShowMore"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          ![
                                            "micro_word",
                                            "micro_polyphonic",
                                            "micro_terms"
                                          ].includes(_vm.data.type)
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "common-color-title margin-top-fourteen"
                                                },
                                                [_vm._v("标题图标")]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c("select-material-btn", {
                                            attrs: {
                                              "data-item": _vm.data.titleData,
                                              type: _vm.data.type,
                                              "icon-text": "添加图片",
                                              path: _vm.data.titleData.cover,
                                              "img-width-height": {
                                                width: "80px",
                                                height: "80px"
                                              }
                                            },
                                            on: {
                                              submitMaterial:
                                                _vm.submitTitleCover
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-submenu",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.data.type !== "input",
                          expression: "data.type !== 'input'"
                        }
                      ],
                      attrs: { index: "4" }
                    },
                    [
                      _c("template", { slot: "title" }, [_vm._v("内容设置")]),
                      _vm._v(" "),
                      _c(
                        "el-menu-item-group",
                        {
                          staticClass: "flex-column",
                          staticStyle: { padding: "0 20px" }
                        },
                        [
                          _vm.data.type === "app_carousel"
                            ? _c("div", { staticClass: "common-color-title" }, [
                                _vm._v(
                                  "默认规则中轮播图会从横幅库中获取，会根据年级切换"
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.data.type === "app_image_navigate"
                            ? _c("div", { staticClass: "common-color-title" }, [
                                _vm._v(
                                  "默认规则中导航会从系统导航中获取，会根据年级切换"
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.data.type === "app_audio_book"
                            ? _c("div", { staticClass: "common-color-title" }, [
                                _vm._v(
                                  "默认规则中会从点学书中获取，会根据所选的年级切换"
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.data.type === "app_home_book_shelf"
                            ? _c("div", { staticClass: "common-color-title" }, [
                                _vm._v("默认规则")
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.data.type === "image_navigate" ||
                          _vm.data.type === "carousel" ||
                          _vm.data.type === "image_advert" ||
                          _vm.data.type === "picture_navigate"
                            ? _c(
                                "div",
                                { staticClass: "flex-column" },
                                [
                                  _vm.data.type === "image_navigate"
                                    ? _c("div", { staticClass: "book-title" }, [
                                        _c("span", [_vm._v("导航数量")])
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.data.type === "image_advert"
                                    ? _c("div", { staticClass: "book-title" }, [
                                        _c("span", [_vm._v("图片数量")])
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.data.type === "picture_navigate"
                                    ? _c("div", { staticClass: "book-title" }, [
                                        _vm._v("单行显示导航个数")
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.data.type === "image_navigate"
                                    ? _c(
                                        "el-radio-group",
                                        {
                                          staticClass:
                                            "margin-top-bottom-seventeen",
                                          attrs: { size: "mini" },
                                          on: {
                                            change:
                                              _vm.changeImageNavigateNumber
                                          },
                                          model: {
                                            value: _vm.data.content.size,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.data.content,
                                                "size",
                                                $$v
                                              )
                                            },
                                            expression: "data.content.size"
                                          }
                                        },
                                        [
                                          _c(
                                            "el-radio-button",
                                            { attrs: { label: 3 } },
                                            [_vm._v("三个")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-radio-button",
                                            { attrs: { label: 4 } },
                                            [_vm._v("四个")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-radio-button",
                                            { attrs: { label: 5 } },
                                            [_vm._v("五个")]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.data.type === "picture_navigate"
                                    ? _c(
                                        "el-radio-group",
                                        {
                                          staticClass:
                                            "margin-top-bottom-seventeen",
                                          attrs: { size: "mini" },
                                          on: {
                                            change:
                                              _vm.changeImageNavigateNumber
                                          },
                                          model: {
                                            value: _vm.data.content.size,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.data.content,
                                                "size",
                                                $$v
                                              )
                                            },
                                            expression: "data.content.size"
                                          }
                                        },
                                        [
                                          _c(
                                            "el-radio-button",
                                            { attrs: { label: 2 } },
                                            [_vm._v("两个")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-radio-button",
                                            { attrs: { label: 3 } },
                                            [_vm._v("三个")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-radio-button",
                                            { attrs: { label: 4 } },
                                            [_vm._v("四个")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-radio-button",
                                            { attrs: { label: 5 } },
                                            [_vm._v("五个")]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.data.type === "image_advert"
                                    ? _c(
                                        "el-radio-group",
                                        {
                                          staticClass:
                                            "margin-top-bottom-seventeen",
                                          attrs: { size: "mini" },
                                          on: {
                                            change:
                                              _vm.changeImageNavigateNumber
                                          },
                                          model: {
                                            value: _vm.data.content.size,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.data.content,
                                                "size",
                                                $$v
                                              )
                                            },
                                            expression: "data.content.size"
                                          }
                                        },
                                        [
                                          _c(
                                            "el-radio-button",
                                            { attrs: { label: 1 } },
                                            [_vm._v("一个")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-radio-button",
                                            { attrs: { label: 2 } },
                                            [_vm._v("两个")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-radio-button",
                                            { attrs: { label: 3 } },
                                            [_vm._v("三个")]
                                          ),
                                          _vm._v(" "),
                                          _vm.data.type === "image_advert"
                                            ? _c(
                                                "el-radio-button",
                                                { attrs: { label: -1 } },
                                                [_vm._v("轮播")]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.data.type === "carousel" ||
                                  _vm.data.type === "app_carousel" ||
                                  (_vm.data.type === "image_advert" &&
                                    _vm.data.content.size === -1)
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "book-title margin-bottom-ten"
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "common-color-title"
                                            },
                                            [_vm._v("设置图片（最多五张）")]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        padding: "15px 15px 0 15px",
                                        background: "#EFEFEF",
                                        "margin-bottom": "20px"
                                      }
                                    },
                                    [
                                      _c(
                                        "draggable",
                                        {
                                          model: {
                                            value: _vm.data.content.parts,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.data.content,
                                                "parts",
                                                $$v
                                              )
                                            },
                                            expression: "data.content.parts"
                                          }
                                        },
                                        [
                                          _c(
                                            "transition-group",
                                            {
                                              attrs: {
                                                name: "fade",
                                                tag: "div"
                                              }
                                            },
                                            [
                                              _vm._l(
                                                _vm.data.content.parts,
                                                function(item, index) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        key: item.id,
                                                        staticClass:
                                                          "flex-column common-sequence"
                                                      },
                                                      [
                                                        _vm.data.type ===
                                                          "image_navigate" ||
                                                        _vm.data.type ===
                                                          "picture_navigate"
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "margin-bottom-ten book-title  flex-row flex-space-between"
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    "导航" +
                                                                      _vm._s(
                                                                        index +
                                                                          1
                                                                      )
                                                                  )
                                                                ]),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "flex-row"
                                                                  },
                                                                  [
                                                                    (_vm.data
                                                                      .type ===
                                                                      "picture_navigate" ||
                                                                      _vm.data
                                                                        .type ===
                                                                        "image_navigate") &&
                                                                    _vm.data
                                                                      .content
                                                                      .parts
                                                                      .length >=
                                                                      1
                                                                      ? _c(
                                                                          "i",
                                                                          {
                                                                            staticClass:
                                                                              "el-icon-delete delete-icon",
                                                                            on: {
                                                                              click: function(
                                                                                $event
                                                                              ) {
                                                                                return _vm.handleDeletePictureAdvertisement(
                                                                                  item.id
                                                                                )
                                                                              }
                                                                            }
                                                                          }
                                                                        )
                                                                      : _vm._e(),
                                                                    _vm._v(" "),
                                                                    _c("i", {
                                                                      staticClass:
                                                                        "iconfont icon-tuozhuaiyidong move-icon",
                                                                      attrs: {
                                                                        title:
                                                                          "托拽排序"
                                                                      }
                                                                    })
                                                                  ]
                                                                )
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _vm._v(" "),
                                                        _vm.data.type ===
                                                          "carousel" ||
                                                        _vm.data.type ===
                                                          "app_carousel" ||
                                                        _vm.data.type ===
                                                          "image_advert"
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "margin-bottom-ten book-title flex-row flex-space-between"
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    "图片" +
                                                                      _vm._s(
                                                                        index +
                                                                          1
                                                                      )
                                                                  )
                                                                ]),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "flex-row"
                                                                  },
                                                                  [
                                                                    (_vm.data
                                                                      .content
                                                                      .size ===
                                                                      -1 &&
                                                                      _vm.data
                                                                        .type ===
                                                                        "image_advert") ||
                                                                    _vm.data
                                                                      .type ===
                                                                      "carousel" ||
                                                                    _vm.data
                                                                      .type ===
                                                                      "app_carousel"
                                                                      ? _c(
                                                                          "i",
                                                                          {
                                                                            staticClass:
                                                                              "el-icon-delete-solid delete-icon",
                                                                            on: {
                                                                              click: function(
                                                                                $event
                                                                              ) {
                                                                                return _vm.handleDeletePictureAdvertisement(
                                                                                  item.id
                                                                                )
                                                                              }
                                                                            }
                                                                          }
                                                                        )
                                                                      : _vm._e(),
                                                                    _vm._v(" "),
                                                                    _c("i", {
                                                                      staticClass:
                                                                        "iconfont icon-tuozhuaiyidong move-icon",
                                                                      attrs: {
                                                                        title:
                                                                          "托拽排序"
                                                                      }
                                                                    })
                                                                  ]
                                                                )
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "flex-row flex-space-between margin-bottom-fourteen"
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticStyle: {
                                                                  width: "80px",
                                                                  height: "80px"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "select-material-btn",
                                                                  {
                                                                    attrs: {
                                                                      "data-item": item,
                                                                      type:
                                                                        _vm.data
                                                                          .type,
                                                                      "icon-text":
                                                                        "选择图片",
                                                                      path:
                                                                        item.image
                                                                    },
                                                                    on: {
                                                                      submitMaterial:
                                                                        _vm.submitMaterial
                                                                    }
                                                                  }
                                                                )
                                                              ],
                                                              1
                                                            ),
                                                            _vm._v(" "),
                                                            _vm.data.type ===
                                                              "image_navigate" ||
                                                            _vm.data.type ===
                                                              "picture_navigate"
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "margin-left",
                                                                    staticStyle: {
                                                                      width:
                                                                        "180px"
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "el-input",
                                                                      {
                                                                        staticClass:
                                                                          "margin-bottom-ten",
                                                                        attrs: {
                                                                          placeholder:
                                                                            "请输入内容",
                                                                          clearable:
                                                                            ""
                                                                        },
                                                                        nativeOn: {
                                                                          pointerdown: function(
                                                                            $event
                                                                          ) {
                                                                            $event.stopPropagation()
                                                                          }
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            item.title,
                                                                          callback: function(
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              item,
                                                                              "title",
                                                                              $$v
                                                                            )
                                                                          },
                                                                          expression:
                                                                            "item.title"
                                                                        }
                                                                      }
                                                                    ),
                                                                    _vm._v(" "),
                                                                    !item.linkSetting
                                                                      ? _c(
                                                                          "el-button",
                                                                          {
                                                                            staticClass:
                                                                              "image-navigate-link",
                                                                            attrs: {
                                                                              type:
                                                                                "primary",
                                                                              icon:
                                                                                "el-icon-plus",
                                                                              size:
                                                                                "mini"
                                                                            },
                                                                            on: {
                                                                              click: function(
                                                                                $event
                                                                              ) {
                                                                                return _vm.addSelectLink(
                                                                                  item
                                                                                )
                                                                              }
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "添加链接"
                                                                            )
                                                                          ]
                                                                        )
                                                                      : _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "link-data"
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "el-tooltip",
                                                                              {
                                                                                attrs: {
                                                                                  effect:
                                                                                    "dark",
                                                                                  placement:
                                                                                    "left-start",
                                                                                  disabled:
                                                                                    item
                                                                                      .linkSetting
                                                                                      .type !==
                                                                                    "Special"
                                                                                }
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticStyle: {
                                                                                      "line-height":
                                                                                        "1.5",
                                                                                      "font-size":
                                                                                        "14px"
                                                                                    },
                                                                                    attrs: {
                                                                                      slot:
                                                                                        "content"
                                                                                    },
                                                                                    slot:
                                                                                      "content"
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        item
                                                                                          .linkSetting
                                                                                          .display
                                                                                          .title
                                                                                      )
                                                                                    ),
                                                                                    _c(
                                                                                      "br"
                                                                                    ),
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        item
                                                                                          .linkSetting
                                                                                          .key
                                                                                          .name
                                                                                      )
                                                                                    )
                                                                                  ]
                                                                                ),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "flex-row align-center flex-space-between",
                                                                                    staticStyle: {
                                                                                      width:
                                                                                        "100%"
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "span",
                                                                                      {
                                                                                        staticClass:
                                                                                          "hidden-one-line link-title"
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            item
                                                                                              .linkSetting
                                                                                              .display
                                                                                              .title
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    ),
                                                                                    _vm._v(
                                                                                      " "
                                                                                    ),
                                                                                    _c(
                                                                                      "i",
                                                                                      {
                                                                                        staticClass:
                                                                                          "el-icon-edit icon",
                                                                                        on: {
                                                                                          click: function(
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.addSelectLink(
                                                                                              item
                                                                                            )
                                                                                          }
                                                                                        }
                                                                                      }
                                                                                    ),
                                                                                    _vm._v(
                                                                                      " "
                                                                                    ),
                                                                                    _c(
                                                                                      "i",
                                                                                      {
                                                                                        staticClass:
                                                                                          "el-icon-delete icon",
                                                                                        on: {
                                                                                          click: function(
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.deleteLink(
                                                                                              item
                                                                                            )
                                                                                          }
                                                                                        }
                                                                                      }
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ]
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                            _vm._v(" "),
                                                            _vm.data.type ===
                                                              "carousel" ||
                                                            _vm.data.type ===
                                                              "image_advert"
                                                              ? _c(
                                                                  "div",
                                                                  [
                                                                    !item.linkSetting
                                                                      ? _c(
                                                                          "el-button",
                                                                          {
                                                                            staticClass:
                                                                              "margin-left add-btn",
                                                                            attrs: {
                                                                              icon:
                                                                                "el-icon-plus",
                                                                              "data-item": item
                                                                            },
                                                                            on: {
                                                                              click: function(
                                                                                $event
                                                                              ) {
                                                                                return _vm.addSelectLink(
                                                                                  item
                                                                                )
                                                                              }
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "添加链接"
                                                                            )
                                                                          ]
                                                                        )
                                                                      : _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "link-data"
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "flex-row align-center"
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "span",
                                                                                  {
                                                                                    staticClass:
                                                                                      "hidden-one-line link-title"
                                                                                  },
                                                                                  [
                                                                                    item
                                                                                      .linkSetting
                                                                                      .key
                                                                                      .resourceType
                                                                                      ? _c(
                                                                                          "span",
                                                                                          {
                                                                                            staticClass:
                                                                                              "type-name"
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              _vm._s(
                                                                                                _vm.resourceTypeName(
                                                                                                  item
                                                                                                    .linkSetting
                                                                                                    .key
                                                                                                    .resourceType
                                                                                                )
                                                                                              )
                                                                                            )
                                                                                          ]
                                                                                        )
                                                                                      : _vm._e(),
                                                                                    _vm._v(
                                                                                      "\n                                  " +
                                                                                        _vm._s(
                                                                                          item
                                                                                            .linkSetting
                                                                                            .display
                                                                                            .title
                                                                                        ) +
                                                                                        "\n                                "
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "i",
                                                                              {
                                                                                staticClass:
                                                                                  "el-icon-edit icon",
                                                                                on: {
                                                                                  click: function(
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.addSelectLink(
                                                                                      item
                                                                                    )
                                                                                  }
                                                                                }
                                                                              }
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "i",
                                                                              {
                                                                                staticClass:
                                                                                  "el-icon-delete icon",
                                                                                staticStyle: {
                                                                                  "margin-left":
                                                                                    "5px"
                                                                                },
                                                                                on: {
                                                                                  click: function(
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.deleteLink(
                                                                                      item
                                                                                    )
                                                                                  }
                                                                                }
                                                                              }
                                                                            )
                                                                          ]
                                                                        )
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e()
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              )
                                            ],
                                            2
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      (_vm.data.content.size === -1 &&
                                        _vm.data.type === "image_advert") ||
                                      _vm.data.type === "carousel" ||
                                      _vm.data.type === "picture_navigate" ||
                                      _vm.data.type === "image_navigate"
                                        ? _c(
                                            "el-button",
                                            {
                                              staticClass:
                                                "margin-bottom-fourteen",
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                type: "primary",
                                                icon: "el-icon-plus",
                                                size: "medium",
                                                disabled:
                                                  _vm.data.content.parts
                                                    .length >= 5 &&
                                                  _vm.data.type !==
                                                    "picture_navigate" &&
                                                  _vm.data.type !==
                                                    "image_navigate"
                                              },
                                              on: {
                                                click:
                                                  _vm.handleAddPictureAdvertisement
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.data.type !==
                                                    "picture_navigate" ||
                                                    _vm.data.type !==
                                                      "image_navigate"
                                                    ? "添加图片"
                                                    : "添加导航"
                                                )
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.data.type === "famous_teachers" ||
                          _vm.data.type === "live" ||
                          _vm.data.type === "micro_audio" ||
                          _vm.data.type === "micro_video"
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "flex-column padding-bottom-fourteen"
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "book-title margin-bottom-fourteen"
                                    },
                                    [_vm._v("内容来源")]
                                  ),
                                  _vm._v(" "),
                                  _vm.data.type === "micro_video" &&
                                  _vm.isCloudRetailPartner
                                    ? _c("video-upload", {
                                        ref: "uploadVideo",
                                        attrs: {
                                          types: "video",
                                          videoid: _vm.videoInfo.vid,
                                          cover: _vm.videoInfo.cover,
                                          "is-cloud-retail-partner":
                                            _vm.isCloudRetailPartner
                                        },
                                        on: {
                                          startUpload: _vm.startVideoUpload,
                                          getResult: _vm.getVideo,
                                          "delect-event": _vm.delectevent
                                        }
                                      })
                                    : _c("public-select-add-content", {
                                        attrs: {
                                          type: _vm.data.type,
                                          "parts-data": _vm.data.content.parts,
                                          "model-key": _vm.data.model
                                        },
                                        on: {
                                          submitDialogue: _vm.submitDialogue,
                                          restoreDefault: _vm.restoreDefault,
                                          changeSize: _vm.changeSize
                                        },
                                        model: {
                                          value: _vm.data.content.size,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.data.content,
                                              "size",
                                              $$v
                                            )
                                          },
                                          expression: "data.content.size"
                                        }
                                      })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.data.type === "micro_word" ||
                          _vm.data.type === "micro_polyphonic" ||
                          _vm.data.type === "micro_terms"
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "flex-column padding-bottom-fourteen"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex-row flex-space-between padding-bottom-fourteen"
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "book-title" },
                                        [_vm._v("超出隐藏")]
                                      ),
                                      _vm._v(" "),
                                      _c("el-switch", {
                                        attrs: { "active-color": "#13ce66" },
                                        model: {
                                          value:
                                            _vm.data.options.isCharacterHidden,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.data.options,
                                              "isCharacterHidden",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "data.options.isCharacterHidden"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _vm.data.options.isCharacterHidden
                                    ? _c(
                                        "span",
                                        { staticClass: "common-color-title" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.data.type ===
                                                "micro_polyphonic"
                                                ? "多音字指拼音的共有"
                                                : _vm.data.type === "micro_word"
                                                ? "字的"
                                                : "词语的"
                                            ) +
                                              "内容超出设置数量后隐藏（隐藏后出现更多按钮）"
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.data.options.isCharacterHidden
                                    ? _c("el-input", {
                                        staticClass: "margin-bottom-fourteen",
                                        attrs: { clearable: "" },
                                        model: {
                                          value: _vm.data.options.hiddenNum,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.data.options,
                                              "hiddenNum",
                                              $$v
                                            )
                                          },
                                          expression: "data.options.hiddenNum"
                                        }
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("word-and-terms-content", {
                                    attrs: { type: _vm.data.type },
                                    model: {
                                      value: _vm.data.content.parts,
                                      callback: function($$v) {
                                        _vm.$set(_vm.data.content, "parts", $$v)
                                      },
                                      expression: "data.content.parts"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.data.type === "micro_title"
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "flex-column common-background-input padding-bottom-fourteen"
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "margin-bottom-fourteen" },
                                    [_vm._v("对齐")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-radio-group",
                                    {
                                      model: {
                                        value: _vm.data.content.align,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.data.content,
                                            "align",
                                            $$v
                                          )
                                        },
                                        expression: "data.content.align"
                                      }
                                    },
                                    [
                                      _c(
                                        "el-radio-button",
                                        { attrs: { label: "left" } },
                                        [_vm._v("居左")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-radio-button",
                                        { attrs: { label: "center" } },
                                        [_vm._v("居中")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-radio-button",
                                        { attrs: { label: "right" } },
                                        [_vm._v("居右")]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "margin-fourteen" },
                                    [_vm._v("标题")]
                                  ),
                                  _vm._v(" "),
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入标题名称" },
                                    model: {
                                      value: _vm.data.content.mainTitle,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.data.content,
                                          "mainTitle",
                                          $$v
                                        )
                                      },
                                      expression: "data.content.mainTitle"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "margin-fourteen" },
                                    [_vm._v("副标题")]
                                  ),
                                  _vm._v(" "),
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入副标题名称" },
                                    model: {
                                      value: _vm.data.content.subTitle,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.data.content,
                                          "subTitle",
                                          $$v
                                        )
                                      },
                                      expression: "data.content.subTitle"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.data.type === "rich_text"
                            ? _c("div", [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "book-title flex-column margin-bottom-fourteen"
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "margin-bottom-fourteen" },
                                      [_vm._v("标题")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "flex-row align-center" },
                                      [
                                        _c("el-input", {
                                          staticClass: "editor-title-input",
                                          model: {
                                            value: _vm.data.content.title,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.data.content,
                                                "title",
                                                $$v
                                              )
                                            },
                                            expression: "data.content.title"
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("el-checkbox", {
                                          model: {
                                            value: _vm.data.content.isShowTitle,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.data.content,
                                                "isShowTitle",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "data.content.isShowTitle"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "book-title flex-column margin-bottom-fourteen"
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "margin-bottom-fourteen" },
                                      [_vm._v("内容")]
                                    ),
                                    _vm._v(" "),
                                    _c("editor", {
                                      attrs: {
                                        config: _vm.editorConfig,
                                        "is-init-content": true
                                      },
                                      model: {
                                        value: _vm.data.content.text,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.data.content,
                                            "text",
                                            $$v
                                          )
                                        },
                                        expression: "data.content.text"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.data.type === "knowledge_goods" ||
                          _vm.data.type === "ranking_list" ||
                          _vm.data.type === "test_paper" ||
                          _vm.data.type === "cloud_retail_goods"
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "flex-column margin-bottom-fourteen"
                                },
                                [
                                  _vm.data.type !== "cloud_retail_goods"
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex-row align-center flex-space-between margin-bottom-fourteen"
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "book-title" },
                                            [_vm._v("特别推荐")]
                                          ),
                                          _vm._v(" "),
                                          _c("el-switch", {
                                            attrs: {
                                              "active-color": "#13ce66"
                                            },
                                            on: {
                                              change:
                                                _vm.specialRecommendationSwitch
                                            },
                                            model: {
                                              value:
                                                _vm.data.content.recommendRule,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.data.content,
                                                  "recommendRule",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "data.content.recommendRule"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.data.content.recommendRule &&
                                  _vm.data.content.selectRecommend
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "knowledge-goods-set-content flex-row align-center flex-space-between margin-bottom-fourteen"
                                        },
                                        [
                                          _vm.data.content.selectRecommend
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "knowledge-goods-input flex-row align-center flex-space-between"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "flex-row align-center"
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "hidden-one-line link-title"
                                                        },
                                                        [
                                                          _vm.data.type !==
                                                          "test_paper"
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "type-name"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.resourceTypeName(
                                                                        _vm.data
                                                                          .content
                                                                          .recommend
                                                                          .resourceType
                                                                      )
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          _vm._v(
                                                            "\n                      " +
                                                              _vm._s(
                                                                _vm.data
                                                                  .type ===
                                                                  "test_paper"
                                                                  ? _vm.data
                                                                      .content
                                                                      .recommend
                                                                      .name
                                                                  : _vm.data
                                                                      .content
                                                                      .recommend
                                                                      .title
                                                              ) +
                                                              "\n                    "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-edit common-icon recommend-icon",
                                                    on: {
                                                      click: _vm.handleRecommend
                                                    }
                                                  })
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c("el-button", {
                                            staticClass:
                                              "common-select-content-button",
                                            attrs: {
                                              type: "primary",
                                              icon: "iconfont icon-zhongzhi",
                                              title: "重置"
                                            },
                                            on: { click: _vm.recommendRefresh }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.data.content.recommendRule &&
                                  !_vm.data.content.selectRecommend
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "knowledge-goods-set-content margin-bottom-fourteen"
                                        },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: { icon: "el-icon-plus" },
                                              on: { click: _vm.handleRecommend }
                                            },
                                            [_vm._v("添加内容")]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.data.content.recommendRule
                                    ? _c(
                                        "span",
                                        { staticClass: "common-color-title" },
                                        [
                                          _vm._v(
                                            "若不选择，默认选择第一个作为推荐内容"
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.data.content.recommendRule &&
                                  _vm.data.type === "test_paper"
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex-row flex-space-between"
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "common-color-title"
                                            },
                                            [_vm._v("推荐背景图")]
                                          ),
                                          _vm._v(" "),
                                          _c("el-switch", {
                                            attrs: {
                                              "active-color": "#13ce66"
                                            },
                                            model: {
                                              value:
                                                _vm.data.content.showBakImg,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.data.content,
                                                  "showBakImg",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "data.content.showBakImg"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "book-title margin-bottom-fourteen"
                                    },
                                    [_vm._v("内容来源")]
                                  ),
                                  _vm._v(" "),
                                  _vm.data.type === "test_paper"
                                    ? _c("public-select-add-content", {
                                        attrs: {
                                          type: _vm.data.type,
                                          "parts-data": _vm.data.content.parts,
                                          "model-key": _vm.data.model
                                        },
                                        on: {
                                          restoreDefault: _vm.restoreDefault,
                                          changeSize: _vm.changeSize,
                                          submitRuleDialogue:
                                            _vm.submitKnowledgeDialogue
                                        },
                                        model: {
                                          value: _vm.data.content.size,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.data.content,
                                              "size",
                                              $$v
                                            )
                                          },
                                          expression: "data.content.size"
                                        }
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.data.type === "knowledge_goods" ||
                                  _vm.data.type === "ranking_list" ||
                                  _vm.data.type === "cloud_retail_goods"
                                    ? _c("public-select-add-content", {
                                        ref: "cloudRetailProductSale",
                                        attrs: {
                                          type: _vm.data.type,
                                          "rule-data": _vm.data.content,
                                          loading: _vm.productLoading,
                                          "model-key": _vm.data.model,
                                          "store-name": _vm.storeKnowledgeName,
                                          options: _vm.data.options
                                        },
                                        on: {
                                          restoreDefault: _vm.restoreDefault,
                                          changeSize: _vm.changeSize,
                                          submitRuleDialogue:
                                            _vm.submitKnowledgeDialogue
                                        },
                                        model: {
                                          value: _vm.data.content.size,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.data.content,
                                              "size",
                                              $$v
                                            )
                                          },
                                          expression: "data.content.size"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.data.type === "types_navigate"
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "flex-column margin-bottom-fourteen"
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "book-title margin-bottom-fourteen"
                                    },
                                    [_vm._v("分类内容")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "types-navigate" },
                                    [
                                      _c(
                                        "draggable",
                                        {
                                          on: {
                                            update: _vm.handleTypeConfigUpdate
                                          },
                                          model: {
                                            value: _vm.data.content,
                                            callback: function($$v) {
                                              _vm.$set(_vm.data, "content", $$v)
                                            },
                                            expression: "data.content"
                                          }
                                        },
                                        [
                                          _vm._l(_vm.data.content, function(
                                            item,
                                            index
                                          ) {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  key: item.id,
                                                  staticClass: "common-sequence"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "flex-row align-center flex-space-between"
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "book-title margin-fourteen"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "分类" +
                                                              _vm._s(index + 1)
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "flex-row"
                                                        },
                                                        [
                                                          _vm.data.content
                                                            .length > 1
                                                            ? _c("i", {
                                                                staticClass:
                                                                  "el-icon-delete-solid delete-icon",
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.deleteTypesNavigate(
                                                                      item,
                                                                      index
                                                                    )
                                                                  }
                                                                }
                                                              })
                                                            : _vm._e(),
                                                          _vm._v(" "),
                                                          _c("i", {
                                                            staticClass:
                                                              "iconfont icon-tuozhuaiyidong move-icon",
                                                            attrs: {
                                                              title: "托拽排序"
                                                            }
                                                          })
                                                        ]
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("el-input", {
                                                    nativeOn: {
                                                      pointerdown: function(
                                                        $event
                                                      ) {
                                                        $event.stopPropagation()
                                                      }
                                                    },
                                                    model: {
                                                      value: item.title,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "title",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "item.title"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          })
                                        ],
                                        2
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          staticClass: "margin-top-fourteen",
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            icon: "el-icon-plus",
                                            type: "primary"
                                          },
                                          on: { click: _vm.addTypesNavigate }
                                        },
                                        [_vm._v("添加分类")]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.data.type === "coupon" ||
                          _vm.data.type === "book_shelf" ||
                          _vm.data.type === "test_questions"
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "flex-column margin-bottom-fourteen"
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "book-title margin-bottom-fourteen"
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.data.type !== "book_shelf" &&
                                            _vm.data.type !== "app_audio_book"
                                            ? "选择" + _vm.selectTitle
                                            : "图书来源"
                                        )
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.data.type === "test_questions"
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex-row flex-space-between"
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "common-color-title"
                                            },
                                            [_vm._v("答题模式")]
                                          ),
                                          _vm._v(" "),
                                          _c("el-switch", {
                                            attrs: {
                                              "active-color": "#13ce66"
                                            },
                                            model: {
                                              value:
                                                _vm.data.content.isAnswerState,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.data.content,
                                                  "isAnswerState",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "data.content.isAnswerState"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("public-select-add-content", {
                                    attrs: {
                                      type: _vm.data.type,
                                      "parts-data": _vm.data.content.parts,
                                      "model-key": _vm.data.model
                                    },
                                    on: {
                                      submitDialogue: _vm.submitDialogue,
                                      restoreDefault: _vm.restoreDefault,
                                      changeSize: _vm.changeSize
                                    },
                                    model: {
                                      value: _vm.data.content.size,
                                      callback: function($$v) {
                                        _vm.$set(_vm.data.content, "size", $$v)
                                      },
                                      expression: "data.content.size"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.data.type === "partner_info"
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "flex-column margin-bottom-fourteen partner-info"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "book-title margin-bottom-fourteen"
                                    },
                                    [
                                      _vm._v("商户信息\n                "),
                                      _c(
                                        "el-tooltip",
                                        {
                                          attrs: {
                                            effect: "dark",
                                            placement: "bottom-start"
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-info",
                                            staticStyle: {
                                              "margin-left": "5px",
                                              color: "#409EFF",
                                              "font-size": "14px"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "line-height": "1.5",
                                                "font-size": "14px"
                                              },
                                              attrs: { slot: "content" },
                                              slot: "content"
                                            },
                                            [
                                              _vm._v(
                                                "商户信息请从   账号 -- 账号信息"
                                              ),
                                              _c("br"),
                                              _vm._v("中进行修改维护")
                                            ]
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _vm.classData.isPlatform
                                    ? _c("search-user", {
                                        staticClass:
                                          "search-partner margin-bottom-fourteen",
                                        attrs: {
                                          "is-return-obj": true,
                                          partnerOpenId:
                                            _vm.data.content.partnerOpenId
                                        },
                                        on: {
                                          receivepartnerId: _vm.receivePartnerId
                                        }
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "common-color-title" },
                                    [_vm._v("商户头像")]
                                  ),
                                  _vm._v(" "),
                                  _c("el-image", {
                                    staticClass:
                                      "partner-logo margin-bottom-fourteen",
                                    attrs: {
                                      src: _vm.getImgUrl(
                                        _vm.data.content.partnerLogo,
                                        "style/width-limit-80"
                                      )
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "common-background-input margin-bottom-fourteen"
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "common-color-title" },
                                        [_vm._v("商户名称")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "partner-statistics-input",
                                          staticStyle: {
                                            "background-color": "#F0F2F5"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.data.content.partnerName)
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "book-title margin-bottom-fourteen"
                                    },
                                    [_vm._v("数量统计")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "common-color-title" },
                                    [_vm._v("统计内容(最多4个)")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "partner-statistics" },
                                    [
                                      _vm._l(_vm.data.content.parts, function(
                                        item,
                                        index
                                      ) {
                                        return _c(
                                          "div",
                                          {
                                            key: index,
                                            staticClass:
                                              "margin-bottom-fourteen"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "flex-row flex-space-between margin-bottom-fourteen"
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    "统计" + _vm._s(index + 1)
                                                  )
                                                ]),
                                                _vm._v(" "),
                                                _c("i", {
                                                  staticClass:
                                                    "el-icon-delete-solid delete-icon",
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.deleteStatistics(
                                                        item
                                                      )
                                                    }
                                                  }
                                                })
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "partner-statistics-input"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      _vm.resourceTypeName(item)
                                                    ) +
                                                    "数\n                  "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "el-popover",
                                        {
                                          attrs: {
                                            placement: "right",
                                            width: "130",
                                            trigger: "manual"
                                          },
                                          model: {
                                            value: _vm.resourceNum,
                                            callback: function($$v) {
                                              _vm.resourceNum = $$v
                                            },
                                            expression: "resourceNum"
                                          }
                                        },
                                        [
                                          _c(
                                            "el-checkbox-group",
                                            {
                                              model: {
                                                value: _vm.checkPartnerList,
                                                callback: function($$v) {
                                                  _vm.checkPartnerList = $$v
                                                },
                                                expression: "checkPartnerList"
                                              }
                                            },
                                            _vm._l(
                                              _vm.$store.state.microPage
                                                .resourceNum,
                                              function(item) {
                                                return _c(
                                                  "div",
                                                  { key: item.value },
                                                  [
                                                    _c(
                                                      "el-checkbox",
                                                      {
                                                        attrs: {
                                                          label: item.value
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(item.name)
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "border-top":
                                                  "1px solid #EBEEF5",
                                                "margin-top": "12px",
                                                "padding-top": "12px"
                                              }
                                            },
                                            [
                                              _c(
                                                "el-link",
                                                {
                                                  attrs: { underline: false },
                                                  on: {
                                                    click: function($event) {
                                                      _vm.resourceNum = false
                                                    }
                                                  }
                                                },
                                                [_vm._v("取消")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-link",
                                                {
                                                  attrs: {
                                                    type: "primary",
                                                    underline: false
                                                  },
                                                  on: {
                                                    click:
                                                      _vm.handlePartnerCheck
                                                  }
                                                },
                                                [_vm._v("确定")]
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-button",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                slot: "reference",
                                                type: "primary",
                                                icon: "el-icon-plus"
                                              },
                                              on: { click: _vm.addStatistics },
                                              slot: "reference"
                                            },
                                            [_vm._v("选择统计数据")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.data.type === "official_account" ||
                          _vm.data.type === "applets"
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "flex-column margin-bottom-fourteen"
                                },
                                [
                                  _vm.data.type === "official_account" &&
                                  !_vm.isZq
                                    ? _c(
                                        "el-radio-group",
                                        {
                                          staticClass: "margin-bottom-fourteen",
                                          attrs: { size: "small" },
                                          on: { change: _vm.handleOfficial },
                                          model: {
                                            value: _vm.data.content.type,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.data.content,
                                                "type",
                                                $$v
                                              )
                                            },
                                            expression: "data.content.type"
                                          }
                                        },
                                        [
                                          _c(
                                            "el-radio-button",
                                            { attrs: { label: 1 } },
                                            [_vm._v("自定义")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-radio-button",
                                            { attrs: { label: 2 } },
                                            [_vm._v("选已绑")]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("official-account", {
                                    attrs: { type: _vm.data.type },
                                    model: {
                                      value: _vm.data.content,
                                      callback: function($$v) {
                                        _vm.$set(_vm.data, "content", $$v)
                                      },
                                      expression: "data.content"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.data.type === "cloud_retail_partner"
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "flex-column margin-bottom-fourteen common-background-input"
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "book-title margin-bottom-fourteen"
                                    },
                                    [_vm._v("店铺名称")]
                                  ),
                                  _vm._v(" "),
                                  _c("el-input", {
                                    staticClass: "margin-bottom-fourteen",
                                    attrs: {
                                      placeholder: _vm.partnerInfo.storeName,
                                      clearable: ""
                                    },
                                    model: {
                                      value: _vm.data.content.storeName,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.data.content,
                                          "storeName",
                                          $$v
                                        )
                                      },
                                      expression: "data.content.storeName"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "book-title margin-bottom-fourteen"
                                    },
                                    [_vm._v("联系电话")]
                                  ),
                                  _vm._v(" "),
                                  _c("el-input", {
                                    staticClass: "margin-bottom-fourteen",
                                    attrs: {
                                      placeholder:
                                        _vm.partnerInfo.operatorPhone,
                                      clearable: ""
                                    },
                                    model: {
                                      value: _vm.data.content.storePhone,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.data.content,
                                          "storePhone",
                                          $$v
                                        )
                                      },
                                      expression: "data.content.storePhone"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "book-title margin-bottom-fourteen"
                                    },
                                    [_vm._v("店铺地址")]
                                  ),
                                  _vm._v(" "),
                                  _c("el-input", {
                                    staticClass: "margin-bottom-fourteen",
                                    attrs: {
                                      type: "textarea",
                                      autosize: { minRows: 3, maxRows: 3 },
                                      placeholder: _vm.partnerInfo.address,
                                      clearable: ""
                                    },
                                    model: {
                                      value: _vm.data.content.storeAddress,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.data.content,
                                          "storeAddress",
                                          $$v
                                        )
                                      },
                                      expression: "data.content.storeAddress"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.data.type === "countdown"
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "flex-column margin-bottom-fourteen common-background-input"
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "margin-fourteen" },
                                    [_vm._v("标题")]
                                  ),
                                  _vm._v(" "),
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入标题名称" },
                                    model: {
                                      value: _vm.data.content.title,
                                      callback: function($$v) {
                                        _vm.$set(_vm.data.content, "title", $$v)
                                      },
                                      expression: "data.content.title"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "margin-fourteen" },
                                    [_vm._v("开始时间")]
                                  ),
                                  _vm._v(" "),
                                  _c("el-date-picker", {
                                    attrs: {
                                      "value-format": "yyyy-MM-dd HH:mm:ss",
                                      type: "datetime",
                                      placeholder: "选择开始日期时间"
                                    },
                                    on: { change: _vm.changeCountdownMode },
                                    model: {
                                      value: _vm.data.content.startTime,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.data.content,
                                          "startTime",
                                          $$v
                                        )
                                      },
                                      expression: "data.content.startTime"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "margin-fourteen" },
                                    [_vm._v("结束时间")]
                                  ),
                                  _vm._v(" "),
                                  _c("el-date-picker", {
                                    attrs: {
                                      "value-format": "yyyy-MM-dd HH:mm:ss",
                                      type: "datetime",
                                      placeholder: "选择结束日期时间"
                                    },
                                    on: { change: _vm.changeCountdownMode },
                                    model: {
                                      value: _vm.data.content.endTime,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.data.content,
                                          "endTime",
                                          $$v
                                        )
                                      },
                                      expression: "data.content.endTime"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "margin-fourteen" },
                                    [_vm._v("活动未开始提示语")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "common-flex flex-space-between"
                                    },
                                    [
                                      _c("el-input", {
                                        staticStyle: {
                                          width: "calc(100% - 40px)"
                                        },
                                        attrs: {
                                          placeholder: "请输入未开始提示语"
                                        },
                                        model: {
                                          value: _vm.data.content.notStartTitle,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.data.content,
                                              "notStartTitle",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "data.content.notStartTitle"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("el-checkbox", {
                                        model: {
                                          value:
                                            _vm.data.content.showNotStartTitle,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.data.content,
                                              "showNotStartTitle",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "data.content.showNotStartTitle"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "margin-fourteen" },
                                    [_vm._v("活动结束提示语")]
                                  ),
                                  _vm._v(" "),
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入结束提示语" },
                                    model: {
                                      value: _vm.data.content.endTitle,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.data.content,
                                          "endTitle",
                                          $$v
                                        )
                                      },
                                      expression: "data.content.endTitle"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.data.type === "tool"
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "flex-column margin-bottom-fourteen "
                                },
                                _vm._l(_vm.data.content.parts, function(
                                  item,
                                  index
                                ) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      staticClass: "tool flex-column"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex-row flex-space-between"
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "book-title" },
                                            [_vm._v(_vm._s(item.nav))]
                                          ),
                                          _vm._v(" "),
                                          _c("el-checkbox", {
                                            model: {
                                              value: item.show,
                                              callback: function($$v) {
                                                _vm.$set(item, "show", $$v)
                                              },
                                              expression: "item.show"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("el-input", {
                                        staticClass: "padding-fourteen",
                                        attrs: {
                                          clearable: "",
                                          placeholder: "团购接龙"
                                        },
                                        model: {
                                          value: item.title,
                                          callback: function($$v) {
                                            _vm.$set(item, "title", $$v)
                                          },
                                          expression: "item.title"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("el-input", {
                                        attrs: {
                                          clearable: "",
                                          placeholder: "社区分享 快速收款"
                                        },
                                        model: {
                                          value: item.describe,
                                          callback: function($$v) {
                                            _vm.$set(item, "describe", $$v)
                                          },
                                          expression: "item.describe"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                }),
                                0
                              )
                            : _vm._e()
                        ]
                      )
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        : _c("div", { staticClass: "more-font not-data common-flex-center" }, [
            _c("span", [
              _vm._v("您还未选择任何组件，请从左侧选择心仪的组件进行编辑吧")
            ])
          ]),
      _vm._v(" "),
      _c("select-link", {
        attrs: { visible: _vm.openLink, "data-item": _vm.linkDataItem },
        on: {
          "update:visible": function($event) {
            _vm.openLink = $event
          }
        },
        model: {
          value: _vm.linkData,
          callback: function($$v) {
            _vm.linkData = $$v
          },
          expression: "linkData"
        }
      }),
      _vm._v(" "),
      _vm.data &&
      (_vm.data.type === "knowledge_goods" ||
        _vm.data.type === "ranking_list") &&
      _vm.openResource
        ? _c(
            "el-dialog",
            {
              staticClass: "select-resource-dialogue",
              attrs: {
                title: "选择作品",
                visible: _vm.openResource,
                width: "1000px"
              },
              on: {
                "update:visible": function($event) {
                  _vm.openResource = $event
                }
              }
            },
            [
              _vm.openResource
                ? _c("select-resource", {
                    attrs: {
                      "resource-type": _vm.selectRecommendData.resourceType,
                      "resource-data": _vm.selectRecommendData
                    },
                    on: { handleResource: _vm.handleResource }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { attrs: { slot: "footer" }, slot: "footer" },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.openResource = false
                        }
                      }
                    },
                    [_vm._v("取消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.submitRadioResource }
                    },
                    [_vm._v("确定")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("select-single-paper", {
        attrs: {
          visible: _vm.openPaper,
          "exam-paper-open-id": _vm.paperSelectOpenId
        },
        on: {
          "update:visible": function($event) {
            _vm.openPaper = $event
          },
          singlePaperSubmit: _vm.submitRadioResource
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }