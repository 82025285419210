var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "common-search" }, [
    _c(
      "div",
      [
        _vm.isShowSelect && !_vm.isZq
          ? _c(
              "el-select",
              {
                staticClass: "book-select",
                attrs: { placeholder: "请选择", size: "small" },
                on: { change: _vm.changeSelectBook },
                model: {
                  value: _vm.select,
                  callback: function($$v) {
                    _vm.select = $$v
                  },
                  expression: "select"
                }
              },
              [
                _c("el-option", {
                  attrs: { label: "增值图书", value: "book" }
                }),
                _vm._v(" "),
                _c("el-option", {
                  attrs: { label: "点学书", value: "audioBook" }
                }),
                _vm._v(" "),
                _c("el-option", { attrs: { label: "电子书", value: "ebook" } }),
                _vm._v(" "),
                _c("el-option", {
                  attrs: { label: "电子书-书橱", value: "ebookCase" }
                })
              ],
              1
            )
          : _vm._e()
      ],
      1
    ),
    _vm._v(" "),
    _vm.isShowSelect
      ? _c(
          "div",
          { staticClass: "flex-row align-center" },
          [
            _vm.select !== "book" && _vm.valueProxy.partnerOpenId
              ? _c(
                  "div",
                  [
                    _c("category", {
                      staticClass: "audio-book-catalogue",
                      attrs: {
                        resourceType: _vm.resourceShelfType,
                        partnerOpenId: _vm.valueProxy.partnerOpenId,
                        size: "small"
                      },
                      model: {
                        value: _vm.valueProxy.type,
                        callback: function($$v) {
                          _vm.$set(_vm.valueProxy, "type", $$v)
                        },
                        expression: "valueProxy.type"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.select === "audioBook"
              ? _c(
                  "el-radio-group",
                  {
                    staticClass: "audio-book-radio-group",
                    attrs: { size: "small" },
                    on: { change: _vm.searchBook },
                    model: {
                      value: _vm.valueProxy.status,
                      callback: function($$v) {
                        _vm.$set(_vm.valueProxy, "status", $$v)
                      },
                      expression: "valueProxy.status"
                    }
                  },
                  [
                    _c("el-radio-button", { attrs: { label: 0 } }, [
                      _vm._v("全部")
                    ]),
                    _vm._v(" "),
                    _c("el-radio-button", { attrs: { label: 1 } }, [
                      _vm._v("已发")
                    ])
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.select === "book"
              ? _c(
                  "el-radio-group",
                  {
                    attrs: { size: "small" },
                    on: { change: _vm.searchBook },
                    model: {
                      value: _vm.bookQuery.status,
                      callback: function($$v) {
                        _vm.$set(_vm.bookQuery, "status", $$v)
                      },
                      expression: "bookQuery.status"
                    }
                  },
                  [
                    _c("el-radio-button", { attrs: { label: undefined } }, [
                      _vm._v("全部")
                    ]),
                    _vm._v(" "),
                    _c("el-radio-button", { attrs: { label: 1 } }, [
                      _vm._v("已发")
                    ]),
                    _vm._v(" "),
                    _c("el-radio-button", { attrs: { label: 2 } }, [
                      _vm._v("待发")
                    ])
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.select === "ebook" || _vm.select === "ebookCase"
              ? _c(
                  "el-select",
                  {
                    staticStyle: { width: "80px" },
                    attrs: { size: "small" },
                    on: { change: _vm.searchBook },
                    model: {
                      value: _vm.valueProxy.status,
                      callback: function($$v) {
                        _vm.$set(_vm.valueProxy, "status", $$v)
                      },
                      expression: "valueProxy.status"
                    }
                  },
                  [
                    _c("el-option", { attrs: { value: 0, label: "全部" } }, [
                      _vm._v("全部")
                    ]),
                    _vm._v(" "),
                    _c("el-option", { attrs: { value: 2, label: "已上" } }, [
                      _vm._v("已上")
                    ]),
                    _vm._v(" "),
                    _c("el-option", { attrs: { value: 1, label: "未上" } }, [
                      _vm._v("未上")
                    ])
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.select === "book"
              ? _c("el-input", {
                  staticClass: "search-book-input",
                  attrs: {
                    placeholder: "关键字搜索",
                    clearable: "",
                    size: "small"
                  },
                  nativeOn: {
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.searchBook($event)
                    }
                  },
                  model: {
                    value: _vm.bookQuery.name,
                    callback: function($$v) {
                      _vm.$set(_vm.bookQuery, "name", $$v)
                    },
                    expression: "bookQuery.name"
                  }
                })
              : _c("el-input", {
                  staticClass: "search-book-input",
                  attrs: {
                    placeholder: "关键字搜索",
                    clearable: "",
                    size: "small"
                  },
                  nativeOn: {
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.searchBook($event)
                    }
                  },
                  model: {
                    value: _vm.valueProxy.name,
                    callback: function($$v) {
                      _vm.$set(_vm.valueProxy, "name", $$v)
                    },
                    expression: "valueProxy.name"
                  }
                }),
            _vm._v(" "),
            _c(
              "search-button",
              {
                staticStyle: { "max-height": "420px" },
                attrs: { size: "small" },
                on: { searchContent: _vm.searchBook }
              },
              [
                _vm.select === "book"
                  ? _c("book-search", { attrs: { query: _vm.bookQuery } })
                  : _c("dialog-filter-panel", {
                      attrs: {
                        "type-name":
                          _vm.select === "audioBook" ? "点学书" : "电子书"
                      },
                      model: {
                        value: _vm.valueProxy,
                        callback: function($$v) {
                          _vm.valueProxy = $$v
                        },
                        expression: "valueProxy"
                      }
                    })
              ],
              1
            ),
            _vm._v(" "),
            _c("el-checkbox", {
              staticClass: "remember-box",
              on: { change: _vm.handleRemember },
              model: {
                value: _vm.remember,
                callback: function($$v) {
                  _vm.remember = $$v
                },
                expression: "remember"
              }
            })
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    !_vm.isShowSelect
      ? _c(
          "div",
          { staticClass: "flex-row align-center" },
          [
            _c("el-input", {
              staticClass: "search-input",
              attrs: { placeholder: "关键字搜索", clearable: "" },
              model: {
                value: _vm.keyword,
                callback: function($$v) {
                  _vm.keyword = $$v
                },
                expression: "keyword"
              }
            }),
            _vm._v(" "),
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.search } },
              [_vm._v("搜索")]
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }